import React from 'react';
import './PricingPlanCard.css';
import './style.css';
import { CheckIcon } from '../icon';
import { useNavigate } from 'react-router-dom';

const PricingPlanCard = ({ planTitle, originalPrice, discountedPrice, planDescription, features, additionalInfo }) => {

    const discountPercentage = ((originalPrice - discountedPrice) / originalPrice) * 100;
const navigate = useNavigate()
    return (
        <div className='pricing-card'>
            <div className='pricing-header'>
                <button className='btn_style'>{planTitle}</button>
            </div>
            <h3 className='pricing-details pt-5'>
                <span className='original-price'>
                    PKR <del>{originalPrice}</del>
                </span>
                <span className='discount-badge'>
                    <button className='btn-discount'>
                        {discountPercentage.toFixed(0)}% off
                    </button>
                </span>
            </h3>

            <h1 className='final-price'>
                {discountedPrice} PKR
            </h1>

            <a href='/register' className="pricing_button text-center">Start free trial</a>
            <hr />
            <p>{planDescription}</p>
            <ul className='order_list_container py-3'>

                {features.map((feature, index) => (
                    <li key={index} className='feature-item'>
                        <CheckIcon className='check-icon' color='green' />
                        <span className='feature-title'>{feature.title}</span>
                        <span className='feature-description'>{feature.description}</span>
                    </li>
                ))}
            </ul>

            <p className='additional-info'>{additionalInfo}</p>
        </div>
    );
};

export default PricingPlanCard;
