import React from 'react';
import CustomNavbar from '../../Component/Navber';
import LetsStart from '../../Component/LetsStart';
import Footer from '../../Component/Footer';
import About from '../../../../asesst/about.jpeg'
import About2 from '../../../../asesst/playstore.png'
import ContactUs from '../../../../asesst/contact-us.png'
import './style.css'
function AboutUs() {
    return (
        <>
            <CustomNavbar />
            <div className='about__sub'>
                <div className='w-100'>
                    <h1 className='about_heading text-white' >About Us</h1>
                    <a href='/' className='home_link'>Home</a>   <span className='slash_tyle'>/</span>  <a className='about_link' href='/about-us'>About</a>
                    {/* Welcome to Tijarah Soft, where innovation meets simplicity in business management. We are a dynamic team of professionals dedicated to empowering businesses with the tools they need to thrive in today's competitive landscape. Our mission is to simplify business operations through cutting-edge software solutions that are easy to use, reliable, and tailored to meet the unique needs of our customers. */}
                </div>
            </div>

            <div className="cards__contain">
                <div className='box__width'>
                    <img src={About2} />
                </div>
                <div className=' box__width '>
                    <h2 className='card_heading'>Who We Are</h2>
                    <p className='description_cards'>Tijarah Soft is more than just a software company; we are your partners in success. Our team comprises industry experts, software engineers, and customer support specialists who are passionate about creating solutions that make a difference. With a deep understanding of the challenges businesses face, we strive to develop software that not only addresses these challenges but also helps our customers grow and succeed.</p>
                    <h2 className='card_heading'>Our Vision</h2>
                    <p className='description_cards'>We envision a world where businesses of all sizes can access the tools they need to manage their operations efficiently, without the complexity and cost that often comes with enterprise-level software. Our goal is to democratize business technology, making it accessible, affordable, and scalable for everyone.</p>
                </div>
                <div className='box__width justify-content-end '>
                    <h2 className='card_heading '>Our Mission</h2>
                    <p className='description_cards'>Our mission is to provide powerful, user-friendly software solutions that streamline business operations, improve productivity, and enable growth. We are committed to continuous innovation, ensuring that our products evolve with the changing needs of our customers and the market.</p>
                    <h2 className='card_heading'>What We Offer</h2>
                    <p className='description_cards'> Tijarah Soft offers a comprehensive suite of business management tools designed to simplify your daily operations. From inventory management and point-of-sale (POS) systems to customer relationship management (CRM) and accounting software, our products are designed to integrate seamlessly into your business, allowing you to focus on what you do best—running your business.</p>
                </div>
                <div className='box__width  d-flex justify-content-end '>
                    <img src={About} width='90%' />

                </div>
            </div>

            <div className='cards__container_main my-5'>
                {/* <h2 className='choose_heading'>Why Choose Us?</h2> */}
                <div className='box_cards'>
                    <p className='text-center'>
                        <img src='https://tse2.mm.bing.net/th?id=OIP.L6ONAtVm9hKaFfI4aOb81wAAAA&pid=Api&P=0&h=220' width='50px' />
                    </p>
                    <h2 className='choose_heading'>User-Friendly Design</h2>
                    <p className='text_cards'>
                        software is designed with the user in mind, featuring intuitive interfaces and easy-to-navigate features.
                    </p>
                </div>

                <div className='box_cards'>
                    <p className='text-center'>
                        <img src='https://tse2.mm.bing.net/th?id=OIP.uWtBBTqjr7cX9pSn33CzTQHaHa&pid=Api&P=0&h=220' width='50px' />
                    </p>
                    <h2 className='choose_heading'>Customizable Solutions</h2>
                    <p className='text_cards'>We understand that every business is unique, so our solutions are highly customizable to meet your specific needs.</p>
                </div>
                <div className='box_cards'>
                    <p className='text-center'>
                        <img src='https://tse4.mm.bing.net/th?id=OIP.kPxYajpd2Nm3JK3HXMPuqwHaHa&pid=Api&P=0&h=220' width='50px' />
                    </p>
                    <h2 className='choose_heading'>Exceptional Support</h2>
                    <p className='text_cards'>Our dedicated support team is always ready to assist you with any questions or issues you may encounter.</p>
                </div>
                <div className='box_cards'>
                    <p className='text-center'>
                        <img src='https://tse3.mm.bing.net/th?id=OIP.AEwWhbLhRUGe7hoCO-jXmAHaHa&pid=Api&P=0&h=220' width='50px' />
                    </p>
                    <h2 className='choose_heading'>Continuous Improvement</h2>
                    <p className='text_cards'>We are committed to regularly updating our software to incorporate the latest technologies and best practices.</p>
                </div>
            </div>

            <div className='Commitment_box'>

                <div>
                    <h2 className='item_heading text-white'>Our Commitment to You</h2>
                    <p className='text-white'>At Tijarah Soft, we believe that our success is measured by your success. We are committed to providing exceptional service, reliable software, and a partnership you can trust. Whether you are a small business just starting or an established enterprise looking to optimize your operations, Tijarah Soft is here to help you achieve your goals.</p>
                    <p className='text-white'>Thank you for choosing Tijarah Soft. We look forward to being a part of your business journey.</p>
                </div>

            </div>
            <div className='disply_contact'>
            <div className='w-100 d-flex justify-content-start'>
                    <img src={ContactUs} width='70%' />
                </div>
                <div className='text_box '>
                    <h2 className='contact_heading'>Contact Us</h2>
                    <p>We would love to hear from you! Whether you have a question, need support, or want to learn more about how Tijarah Soft can help your business, feel free to reach out to us:</p>

                    <h3 className='contact_heading pt-3'>Tijarah Soft</h3>
                    <a href='mailto:support@tijarahsoft.com' className='email_link'>support@tijarahsoft.com</a>
                    <p>Together, let's build a brighter future for your business.</p>
                </div>
               
            </div>
            <div className='content_container'>
                {/* <p className='content'>
                    Welcome to Tijarah Soft, where innovation meets simplicity in business management. We are a dynamic team of professionals dedicated to empowering businesses with the tools they need to thrive in today's competitive landscape. Our mission is to simplify business operations through cutting-edge software solutions that are easy to use, reliable, and tailored to meet the unique needs of our customers.                </p> */}






            </div>
            <LetsStart />
            <Footer />
        </>
    )
}

export default AboutUs;