import React, { useRef } from "react";
import { useInView, motion } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeholder from "../../../../asesst/placeholder.jpeg";
import ProductCard from "../Cards/ProductCard";
import { createSlug } from "../../../../helper";

function CategorySlider({ categories }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px 0px" });

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className="main__hero_sec  category-lider screen-spacing">
      <Slider {...settings}>
        {categories?.map((category, index) => (
          <div>
            <ProductCard
              key={index}
              imageUrl={category?.image ?? Placeholder}
              imageAlt={category?.category}
              title={category?.category}
              link={`/category/${createSlug(category?.category)}`}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CategorySlider;
