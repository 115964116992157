import React from 'react';
import CustomNavbar from '../../Component/Navber';
import LetsStart from '../../Component/LetsStart';
import Footer from '../../Component/Footer';
import './style.css'
function ServicePolicy() {
    return (
        <>
            <CustomNavbar />
            <div className='heading_container'>
                <h1 className='main_heading' >Service Policy</h1>
            </div>
            <div className='content_container'>
                <p className='date_update'>Last updated: 25-08-2024</p>
                <ol className='order_list_container'>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Service Overview</h2>
                        <p>Tijarah Soft provides a comprehensive suite of Point of Sale (POS) software solutions tailored for various business needs. Our services include software installation, configuration, user training, and ongoing support. We strive to ensure that our solutions meet the highest standards of quality and reliability.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Service Availability</h2>
                        <p>Our services are available to clients during standard business hours (9:00 AM - 6:00 PM, Monday to Friday). Support outside these hours may be available upon request and may incur additional charges.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Support and Maintenance</h2>
                        <p>We offer technical support to address issues related to the software. This includes troubleshooting, bug fixes, and assistance with software functionality. Regular updates and maintenance are provided to ensure the software operates smoothly and incorporates the latest features and security enhancements.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Client Responsibilities</h2>
                        <p>Clients are responsible for providing accurate and complete information regarding their business needs and software requirements. This ensures that Tijarah Soft can deliver services that meet their expectations.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Service Changes</h2>
                        <p>Any changes to the service agreement, including modifications to software features or additional services, must be documented and agreed upon by both parties.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Confidentiality</h2>
                        <p>We are committed to maintaining the confidentiality of your business data. All information shared with Tijarah Soft will be handled with the utmost care and used solely for the purpose of delivering our services.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Limitation of Liability</h2>
                        <p>Tijarah Soft is not liable for any indirect, incidental, or consequential damages resulting from the use of our software or services. Our liability is limited to the extent permitted by law.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Contact Us</h2>
                        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                        <p><strong>Tijarah Soft</strong></p>
                        <p><a href='mailto:support@tijarahsoft.com'>support@tijarahsoft.com</a></p>
                    </li>
                </ol>
                <p className='content'>
                    By using Tijarah Soft, you consent to the terms of this Privacy Policy.
                </p>
                <p className='content'>
                    Thank you for trusting Tijarah Soft with your information.
                </p>
            </div>
            <LetsStart />
            <Footer />
        </>
    )
}

export default ServicePolicy;