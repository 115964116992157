import React from 'react'

function PageNotFound() {
    return (
        <div style={{ width: "100%", height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div>
                <h1> <span style={{ fontSize: "45px", fontWeight: "800" }}>404</span> Page Not Found</h1>
            </div>
        </div>
    )
}

export default PageNotFound
