import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../../../asesst/logo.png'
import './style.css';
import { useNavigate } from 'react-router-dom';

function CustomNavbar() {
    const navigate = useNavigate()
    const handleNavigate = () => {
        navigate("/register")
    }
    return (
        <Navbar expand="lg" className='navber_style compelet__screen_perfect'>
            <Container fluid>
                <Navbar.Brand href="/" className='text-white fw-bold fs-3'><img src={Logo} alt='Tijarah Soft' className='home_logo' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" className="custom-toggler" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="m-auto my-2 my-lg-0" >
                        <Nav.Link href="/" className='text-white px-3 link__style'>Home</Nav.Link>
                        <Nav.Link href="/features" className='text-white px-3 link__style'>Features</Nav.Link>
                        <Nav.Link href="/download" target='_blank' className='text-white px-3 link__style'>Download</Nav.Link>
                        <Nav.Link href="/about-us" className='text-white px-3'>About us</Nav.Link>
                        <Nav.Link href="/pricing-plans" className='text-white px-3 link__style'>Pricing Plans</Nav.Link>
                    </Nav>
                    <Form className="d-flex btn__style">
                        <Button onClick={handleNavigate} style={{ borderRadius: 25, paddingLeft: 18, paddingRight: 18, backgroundColor: 'transparent', borderWidth: 1, borderColor: '#ffffff' }}>Start Free Trial</Button>
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CustomNavbar;
