import React, { useContext, useState } from "react";
import DropdownDesktop from "./dropdownDesktop";
import { Link } from "react-router-dom";
import DropdownMobile from "./dropdownMobile";
import { Category, SubCategory } from "../constant";
import { ContentContext } from "../../../../store";

const LeftNav = () => {
  const [hide, setHide] = useState(false);
  const handleToggle = () => {
    setHide(!hide);
  };

  const { categories, domainInfo } = useContext(ContentContext);
  return (
    <div>
      {" "}
      <div className="menu-toggle " onClick={handleToggle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M22 18.005c0 .55-.446.995-.995.995h-8.01a.995.995 0 0 1 0-1.99h8.01c.55 0 .995.445.995.995M22 12c0 .55-.446.995-.995.995H2.995a.995.995 0 1 1 0-1.99h18.01c.55 0 .995.446.995.995m-.995-5.01a.995.995 0 0 0 0-1.99H8.995a.995.995 0 1 0 0 1.99z"
          />
        </svg>
      </div>
      <div
        className={`dropdown_section px-5 ${hide ? "show-menu" : "hide-menu"} `}
      >
        {/* desktop  */}
        <div className="nav-tab">
          <Link to="/shop">Shop </Link>
        </div>
        <div className="nav-tab category-tab ">
          <Link to="/category">
            Categories{" "}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="0.88em"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7L86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                />
              </svg>
            </span>
          </Link>
          <DropdownDesktop categoryList={categories} />
        </div>
        {/* mobile  */}
        <div className="close-nav-icon d-large-none mt-5" onClick={handleToggle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
            />
          </svg>
        </div>
        <div className="d-large-none ">
          <DropdownMobile title="Shop" link="/shop" dropdown={false} />
          <DropdownMobile
            title="Category"
            link={"/category"}
            categoryList={categories}
            dropdown={true}
          />
          {/* <button className="hdt-dark-btn me-2 hdt-align-center hdt-btn hdt-btn-solid hdt-button hdt-font-semibold ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.3em"
              height="1.3em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="m12.1 18.55l-.1.1l-.11-.1C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5c1.54 0 3.04 1 3.57 2.36h1.86C13.46 6 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5c0 2.89-3.14 5.74-7.9 10.05M16.5 3c-1.74 0-3.41.81-4.5 2.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5c0 3.77 3.4 6.86 8.55 11.53L12 21.35l1.45-1.32C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3"
              />
            </svg>
            &nbsp; WishList
          </button> */}

          <div className="mt-5">
            <span className="hdt-flex hdt-flex-col hdt-text-base hdt-font-normal">
              <span>
                Email:{" "}
                <b>
                  {" "}
                  <Link to={`mailto: ${domainInfo?.topNavbarEmail}`}>
                    {" "}
                    {domainInfo?.topNavbarEmail}
                  </Link>
                </b>
              </span>
              <span>
                Phone:{" "}
                <b>
                  <Link to={`tel: ${domainInfo?.topNavbarMobile}`}>
                    {domainInfo?.topNavbarMobile}
                  </Link>
                </b>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className={hide && "backdrop"} onClick={handleToggle}></div>
    </div>
  );
};

export default LeftNav;
