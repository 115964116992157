import React from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';

function Button({ label, whiteBlue }) {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate("/register")
  }
  return (
    <button
      className={`star__btn ${whiteBlue ? 'star__btn--white-blue' : ''}`}
      onClick={handleNavigate}
    >
      {label}
    </button>
  );
}

export default Button;
