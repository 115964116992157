import React from "react";
import { Link } from "react-router-dom";
import { motion, useInView } from "framer-motion";
import Placeholder from "../../../../../asesst/placeholder.jpeg";

const BannerCard = ({
  id,
  imageUrl,
  heading,
  subheading,
  buttonLink,
  buttonText,
}) => {
  // Create a ref to detect when the component is in view
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true }); // Trigger animation once

  // Animation variants for the heading and subheading
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    imageUrl && (
      <div id={id} className="hdt-banner__item hdt-col hdt-snap-center">
        <div
          style={{ backgroundColor: !imageUrl ? "#000000" : "" }}
          className="hdt-item_inner hdt-rounded hdt-rounded-f hdt-media-overlap-content hdt-media-overlap-content-banner"
        >
          {imageUrl && (
            <picture className="hdt-media-wrapper">
              <img
                src={imageUrl ?? Placeholder}
                alt=""
                width="690"
                loading="lazy"
                sizes="(min-width: 1150px) calc((1440px - (15px * 2)) * 0.33), (min-width: 768px) calc((100vw - 30px - (15px * 2)) * 0.33), calc((100vw - (15px * -0.25)) * 1.33 - 30px)"
                className="hdt-object-pos-mb three-banner-image"
              />
            </picture>
          )}
          <div className="hdt-grid hdt-h-full hdt-pe-none">
            <div className="hdt-place-self-end-center md:hdt-place-self-end-center hdt-pe-none mb-66">
              <div className="hdt-content_wrap hdt-oh hdt-pe-none">
                <div className="hdt-content hdt-grid hdt-text-center md:hdt-text-center">
                  {/* Animate Subheading */}
                  <motion.p
                    ref={ref} // Attach the ref to the motion element
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                    variants={variants}
                    transition={{ duration: 0.5 }} // Animation duration
                    className="hdt-subheading hdt-s-text2 hdt-base hdt-font-bold"
                  >
                    {subheading}
                  </motion.p>

                  {/* Animate Heading */}
                  <motion.h3
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                    variants={variants}
                    transition={{ duration: 0.5, delay: 0.2 }} // Delay for heading animation
                    className="hdt-heading hdt-s-text hdt-h5 hdt-font-semibold banner-heading"
                  >
                    {heading}
                  </motion.h3>
                </div>
              </div>
            </div>
          </div>
          <motion.a
            className="hdt-btn mt-4 hdt-text-base hdt-oh hdt-inline-flex hdt-align-center hdt-justify-center hdt-relative hdt-btn-solid hdt-font-semibold banner-link-btn"
            initial="hidden"
            t
            animate={isInView ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: 0.5, delay: 0.2 }}
            href={buttonLink}
          >
            <span>{buttonText}</span>
          </motion.a>
        </div>
      </div>
    )
  );
};

export default BannerCard;
