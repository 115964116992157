import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ name, logo }) => {
  return (
    <div className="logo_section">
      <Link to="/">
        {logo ? (
          <img src={logo} width={200} />
        ) : (
          <h1 className="mb-0">{name}</h1>
        )}
      </Link>
    </div>
  );
};

export default Logo;
