import axios from "axios";
import { BASE_URL } from "..";

export const fetchProducts = async (domain) => {
    const response = await axios.get(`${BASE_URL}/product/all`, {
        headers: {
            domain
        }
    });
    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
}

export const fetchProduct = async ({ productid, storename, userid }) => {
    const response = await axios.get(`${BASE_URL}/product`, {
        headers: {
            productid,
            storename,
            userid
        }
    });
    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
}
