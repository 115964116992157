import React from 'react';
import CustomCarousel from '../cursole';
import './style.css'; // Import your CSS file for styling

const Slider = () => {
    return (
        <div>
            <div className='compelet__screen_perfect  sider__main'>
                <div className='postion_styel_img'>
                </div>
                <div className='slider__outlet'>
                    <h3 className=' sider_header'><span>Tailored Solutions </span >for <span>Every Industry</span></h3>
                    <p className='sider_pera'>At Tijarah Soft, we deliver industry-specific POS solutions designed to meet the unique needs of your business. Our flexible platform adapts to various sectors, helping you streamline operations, enhance customer experiences, and drive growth.</p>
                </div>
                <CustomCarousel />
            </div>

        </div>


    );
};

export default Slider;
