import React, { useState, useMemo, useContext } from "react";
import EmptyCart from "../EmptyCart";
import Placeholder from "../../../../../../asesst/placeholder.jpeg";
import BestProductCard from "../../../../Component/Cards/BestProductCard";
import BestProduct from "../../../../Component/BestProduct";
import { Link, useNavigate } from "react-router-dom";
import { createSlug } from "../../../../../../helper";
import { ContentContext } from "../../../../../../store";
import { useEffect } from "react";

const ProductTable = ({ domain, initialProducts }) => {
  const { setselectedItems } = useContext(ContentContext);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const checkQty = initialProducts?.map((product) => ({
      ...product,
      selectedQty: product.selectedQty ?? 1, // Initialize selectedQty to 1 if not set
    }));
    setProducts(checkQty);
  }, []);

  const updateLocalStorage = (updatedProducts) => {
    localStorage.setItem(`${domain}-carts`, JSON.stringify(updatedProducts));
  };

  const handleIncreaseQty = (index) => {
    const updatedProducts = [...products];
    if (updatedProducts[index]?.selectedQty < updatedProducts[index]?.count) {
      updatedProducts[index].selectedQty += 1;
    }
    setProducts(updatedProducts);
    setselectedItems(updatedProducts);
    updateLocalStorage(updatedProducts);
  };

  const handleDecreaseQty = (index) => {
    const updatedProducts = [...products];
    if (updatedProducts[index].selectedQty > 1) {
      updatedProducts[index].selectedQty -= 1;
    } else {
      updatedProducts.splice(index, 1);
    }
    setProducts(updatedProducts);
    setselectedItems(updatedProducts);
    updateLocalStorage(updatedProducts);
  };

  // Calculate subtotal using useMemo for performance optimization
  const subtotal = useMemo(() => {
    return products?.reduce((acc, product) => {
      return acc + Number(product.retailPrice) * product.selectedQty;
    }, 0);
  }, [products]);
  const navigate = useNavigate();

  const handlebtnClick = () => {
    updateLocalStorage(products);
    navigate("/checkout"); // Navigate to the home page
  };

  return (
    <div>
      {/* <EmptyCart/> */}
      <form
        willChangeCart=""
        action="/cart"
        method="POST"
        id="CartPage-Form"
        section-id="template--22004517896512__main"
      >
        <input
          type="hidden"
          name="attributes[products_items_per_row]"
          value=""
        />
        <div className="product_cart_main">
          <div className="table__main_container">
            <table className="table__style">
              <thead className="table_head">
                <tr>
                  <th className="text-start procut_table_heading">Product</th>
                  <th className="procut_table_heading">Price</th>
                  <th className="procut_table_heading">Quantity</th>
                  <th className="procut_table_heading">Total</th>
                </tr>
              </thead>
              {products?.map((item, index) => (
                <tbody key={index} className="table_body">
                  <tr>
                    <td>
                      <div className="table_img_product ">
                        <div>
                          <Link
                            to={`/product/${createSlug(item?.itemname)}`}
                            // className="hdt-page-cart__img hdt-block hdt-relative hdt-oh"
                            style={{ "--aspect-ratioapt": 1.0 }}
                          >
                            <img
                              src={item?.image ?? Placeholder}
                              alt={item?.title}
                              width="70"
                              height="700"
                              loading="lazy"
                              sizes="(max-width: 767px) 80px, 160px"
                            />
                          </Link>
                        </div>
                        <div className="product-info">
                          <Link
                            to={`/product/${createSlug(item?.itemname)}`}
                            style={{ "--aspect-ratioapt": 1.0 }}
                          >
                            <h5 className="product_details">
                              {item?.itemname} -{" "}
                              {item?.selectedVariant && (
                                <span className="product-info">
                                  {item?.selectedVariant}
                                </span>
                              )}
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="product_prices_text">
                      {`${
                        item?.retailPrice ? Number(item?.retailPrice) : "00"
                      }`}
                    </td>
                    <td className="product_add">
                      <span className="add_product_text">
                        <span onClick={() => handleDecreaseQty(index)}>-</span>{" "}
                        {item?.selectedQty}{" "}
                        <span onClick={() => handleIncreaseQty(index)}>+</span>
                      </span>
                    </td>
                    <td className="product_prices_text">
                      {`${
                        item?.retailPrice && item?.selectedQty
                          ? Number(item.retailPrice) * Number(item.selectedQty)
                          : "00"
                      }`}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
            {/* <div>
              <h3 className='not_order_head'>Add Order Note</h3>
              <textarea placeholder='how can we help you ?' className='not_down_product p-4 fs-5'></textarea>
            </div> */}
          </div>
          <div>
            <div className="Check_out_container checkout_width ms-md-3">
              <div className="shipping__head">
                <div>
                  <p className="shipping_text">Checkout</p>
                </div>
              </div>
              <div className="px-4"></div>
              <div className="">
                <table className="">
                  <thead className="table_head">
                    <tr>
                      <th className="text-start procut_table_heading">
                        Product
                      </th>
                      <th className="procut_table_heading">Price</th>
                      <th className="procut_table_heading">Quantity</th>
                      <th className="procut_table_heading">Total</th>
                    </tr>
                  </thead>
                  {products?.map((item, index) => (
                    <tbody key={index} className="table_body">
                      <tr>
                        <td>
                          <div className="checkout_table_img">
                            <div>
                              <Link
                                to={`/product/${createSlug(item?.itemname)}`}
                                className="hdt-page-cart__img hdt-block hdt-relative hdt-oh"
                                style={{ "--aspect-ratioapt": 1.0 }}
                              >
                                <img
                                  src={item?.image ?? Placeholder}
                                  alt={item?.title}
                                  width="70"
                                  height="700"
                                  loading="lazy"
                                  sizes="(max-width: 767px) 80px, 160px"
                                />
                              </Link>
                            </div>
                            <div className="product-info">
                              <h5 className="product_details2">
                                {item?.itemname}
                              </h5>
                            </div>
                          </div>
                        </td>
                        <td className="product_prices_text">
                          {`${
                            item?.retailPrice ? Number(item?.retailPrice) : "00"
                          }`}
                        </td>
                        <td className="product_add">
                          <span className="product_prices_text">
                            {item?.selectedQty}
                          </span>
                        </td>
                        <td className="product_prices_text">
                          {`${
                            item?.retailPrice && item?.selectedQty
                              ? Number(item.retailPrice) *
                                Number(item.selectedQty)
                              : "00"
                          }`}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
              <div className="price_container">
                <div>
                  <p className="SUbtotal_head">Subtotal</p>
                </div>
                <div>
                  <p className="Subtotal_price">RS: {subtotal}</p>
                </div>
              </div>
              <div className="product_title">
                <div className="check_out_parent">
                  <button
                    onClick={handlebtnClick}
                    className="Check_out_button "
                  >
                    Check Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <BestProduct initialVisible={3} products={products} domain={domain} />
        </div>
      </form>
    </div>
  );
};

export default ProductTable;
