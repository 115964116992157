import React from 'react';
import './style.css';
import cardicon from '../../../../asesst/cardicon.png';
import marksgroup from '../../../../asesst/Maskgroup.png';
import wifioff from '../../../../asesst/wifioff.png';
import datagroup from '../../../../asesst/datagroup.png';
import barcodelable from '../../../../asesst/barcodelable.png';
import recipet from '../../../../asesst/recieopt.png';
import Button from '../../../../component/Button';

function Features() {
  const cardarr = [
    {
      img: cardicon,
      head: 'Add Multiple Items List',
      text: 'Easily add multiple items with our POS softwares bulk entry feature, streamlining inventory management and checkout processes.',
    },
    {
      img: marksgroup,
      head: 'Tijarah Soft SUPPORT',
      text: 'Tijarah offers integrated help, support tickets, and answers to common questions on the Nimbus Forum.',
    },
    {
      img: wifioff,
      head: 'OFFLINE SALES',
      text: 'Need quick receipts without internet? Use Offline Sales for local operation and background cloud syncing.',
    },
    {
      img: datagroup,
      head: 'DATA SECURITY',
      text: 'Data security is the top priority for any retail POS and inventory software in the Cloud. Nimbus RMS is hosted on Microsoft Azure, the most secure and state-of-the-art cloud infrastructure available.',
    },
    {
      img: barcodelable,
      head: 'BARCODE LABELS',
      text: 'Tijarah makes barcode printing easy. You can use sheets or label printers to print barcodes, either on demand or when receiving goods.',
    },
    {
      img: recipet,
      head: 'RECEIPT CUSTOMIZATION',
      text: 'You can customize receipts to meet your business needs by adding your company logo, adjusting the receipt caption, and including a personalized message in the footer.',
    },
  ];


  return (
    <div className='compelet__screen_perfect  featcure__design'>
      <div className='bg_shaow' >

      </div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', paddingBottom: 140 }}>
        <div>
          <h1 className='text-center feature_head' id='feature' >
          <span data-aos="fade-up">  Revolutionize Your Business </span> <span data-aos="fade-up" >Operations with</span> <span data-aos="fade-up" > Tijarah Soft</span>
          </h1>
          <p className='text-center feature_pera'>
            Discover the power of Tijarah Soft’s integrated POS solution, crafted to streamline and enhance every aspect of your operations. Our platform delivers seamless sales management, real-time inventory updates, and advanced analytics, all through a customizable dashboard. Transform your business with our all-in-one system, designed for maximum efficiency and control.
          </p>
        </div>
        <div className='btn__started'>
          <Button label='Start Free Trial' />
        </div>
      </div>

      <div className='card_section_main'>
        {cardarr.map((e, i) => (
          <div key={i} className='card_style'>
            {/* <div className={`icon_group ${centerIndices.includes(i) ? 'center_card' : ''}`}> */}
            <img src={e.img} alt='' className='icon_img' />
            {/* </div> */}
            <div>
              <h3 className='card__heading'>{e.head}</h3>
              <p className='card_pera'>{e.text}</p>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
}

export default Features;
