import axios from "axios";
import { BASE_URL } from "..";

export const createView = async (data) => {
    const ip = await axios.get('https://api.ipify.org?format=json');
    const response = await axios.post(`${BASE_URL}/pageviews/create`, { ...data, ip: ip.data.ip }, {
        headers: {
            domain: data?.domain
        }
    });
    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
}


export const createVisitor = async (data) => {
    const ip = await axios.get('https://api.ipify.org?format=json');
    const response = await axios.post(`${BASE_URL}/visitor/create`, { ...data, ip: ip.data.ip }, {
        headers: {
            domain: data?.domain
        }
    });
    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
}