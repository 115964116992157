import axios from "axios";
import { BASE_URL } from "..";

export const fetchCategories = async (domain) => {
    const response = await axios.get(`${BASE_URL}/category/all`, {
        headers: {
            domain
        }
    });
    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
}

export const fetchCategoriesProducts = async (domain, category) => {
    const response = await axios.get(`${BASE_URL}/category`, {
        headers: {
            domain,
            category
        }
    });
    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
}
