import axios from "axios";
import { BASE_URL } from "..";

export const createOrder = async (domain, data) => {
    const response = await axios.post(`${BASE_URL}/order/create`, data, {
        headers: {
            domain
        }
    });
    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
}
