import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentContext } from "../../../../store";
import Banner from "../Cart/Component/Banner";
import Placeholder from "../../../../asesst/placeholder.jpeg";
import AnnouncementBar from "../../Component/AnnouncementBar";
import BestProduct from "../../Component/BestProduct";
import Footer from "../../Component/Footer";
import Navbar from "../../Component/Navbar";
import "./style.css";
import { createView } from "../../../../apis/views";
import { decodeSlug, fetchDomain } from "../../../../helper";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import OpenCart from "../../Component/OpenCart";
import WhatsappBtn from "../../Component/WhatsappBtn";
import ContentLoader from "react-content-loader";

function Product({ domain }) {
  const { products, domainInfo, setIsModalOpen } = useContext(ContentContext);
  const [product, setProduct] = useState({}); // Initial quantity (2 in cart)
  const { title } = useParams();
  const name = decodeSlug(title);
  const [selectedVatiant, setSelectedVatiant] = useState("");
  const [selectedPrice, setSelectedPrice] = useState(0);
  const ProductLoader = () => (
    <ContentLoader
      speed={3}
      //   width={350}

      viewBox="0 0 476 124"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="200" height="15" />
      <rect x="0" y="26" rx="3" ry="3" width="250" height="15" />
      <rect x="0" y="50" rx="3" ry="3" width="310" height="15" />
      <rect x="0" y="75" rx="3" ry="3" width="350" height="15" />
      <rect x="0" y="100" rx="3" ry="3" width="178" height="15" />
      <rect x="0" y="125" rx="3" ry="3" width="150" height="15" />
      <rect x="0" y="150" rx="3" ry="3" width="200" height="15" />
    </ContentLoader>
  );
  const createPageView = async () => {
    try {
      await createView({ domain: fetchDomain(), productId: product?.id });
    } catch (error) {}
  };

  useEffect(() => {
    document.documentElement.style.fontSize = "62.5%";
    return () => {
      document.documentElement.style.fontSize = "16px";
    };
  }, []);

  const price =
    product?.itemType === "Variants"
      ? Math.min(
          ...product?.combinations?.map(
            (combination) => Number(combination?.retailPrice) || 0
          )
        )
      : Number(product?.retailPrice);

  useEffect(() => {
    const productData = products?.find((product) =>
      product.itemname?.toLowerCase()?.includes(name?.toLowerCase())
    );
    setSelectedPrice(price);
    setProduct(productData);
    createPageView();
    if (product?.itemType === "Variants") {
      const selectedVariant = product?.combinations?.find(
        (combination) => Number(combination?.retailPrice) === price
      );
      setSelectedVatiant(selectedVariant?.variantName);
    }
  }, [products]);

  const [isInCart, setIsInCart] = useState(false);

  const [updatedPorduct, setUpdatedPorduct] = useState([]);
  const handleAddCart = async () => {
    const storedProducts = localStorage.getItem(`${domain}-carts`);
    let products = storedProducts ? JSON.parse(storedProducts) : [];

    // Check if the product is already in the cart
    const productIndex = products.findIndex((item) => item.id === product?.id);

    if (productIndex > -1) {
      // Product is in the cart, remove it
      products.splice(productIndex, 1);
    } else {
      if (product?.itemType === "Variants") {
        product.retailPrice = selectedPrice;
        product.selectedVariant = selectedVatiant;
      }
      products.push(product);
      setIsModalOpen(true);
    }
    if (product?.itemType === "Variants") {
      if (selectedVatiant && selectedPrice) {
        localStorage.setItem(`${domain}-carts`, JSON.stringify(products));
        setUpdatedPorduct(products);
      } else {
        toast.error("Select Variant");
      }
    } else {
      localStorage.setItem(`${domain}-carts`, JSON.stringify(products));
      setUpdatedPorduct(products);
    }
    checkProductInCart(); // Re-check if product is in the cart after adding/removing
  };

  const checkProductInCart = () => {
    const carts = JSON.parse(localStorage.getItem(`${domain}-carts`)) || [];
    const productInCart = carts.some((item) => item.id === product?.id);
    setIsInCart(productInCart);
  };
  useEffect(() => {
    checkProductInCart();
  }, [product]);
  console.log(product, "product");

  const handleChangeVariant = (variant) => {
    const selectedCombination = product?.combinations?.find((combination) =>
      combination?.variantName?.includes(variant?.label)
    );
    if (selectedCombination) {
      const variantParts = selectedCombination?.variantName.split("-");
      const selectedIndex = variantParts.findIndex((v) => v === variant?.label);
      if (selectedIndex !== -1) {
        setSelectedVatiant(variantParts.join("-"));
      }
      setSelectedPrice(
        selectedCombination?.retailPrice
          ? Number(selectedCombination?.retailPrice)
          : ""
      );
    }
  };

  useEffect(() => {
    if (domainInfo?.tawkTo) {
      // Parse and insert the script into the document
      const scriptTag = document.createElement("div");
      scriptTag.innerHTML = domainInfo.tawkTo; // Assuming `tawkTo` contains the HTML with the script
      const scriptElement = scriptTag.querySelector("script");

      // Execute the script if available
      if (scriptElement) {
        const newScript = document.createElement("script");
        newScript.type = "text/javascript";
        newScript.async = true;
        newScript.innerHTML = scriptElement.innerHTML; // Set script content
        document.body.appendChild(newScript);

        // Clean up the script when component unmounts
        return () => {
          document.body.removeChild(newScript);
        };
      }
    }
  }, [domainInfo?.tawkTo]);

  let products_ = localStorage.getItem(`${domain}-carts`);

  console.log(updatedPorduct, "updatedProduct");

  const parsedProducts = products_ ? JSON.parse(products_) : [];
  // const handleIncreaseQty = (index) => {
  //   const updatedProducts = [...products];
  //   if (updatedProducts[index]?.selectedQty < updatedProducts[index]?.count) {
  //     updatedProducts[index].selectedQty += 1;
  //   }
  //   setProducts(updatedProducts);
  //   setselectedItems(updatedProducts);
  //   updateLocalStorage(updatedProducts);
  // };

  // const handleDecreaseQty = (index) => {
  //   const updatedProducts = [...products];
  //   if (updatedProducts[index].selectedQty > 1) {
  //     updatedProducts[index].selectedQty -= 1;
  //   } else {
  //     updatedProducts.splice(index, 1);
  //   }
  //   setProducts(updatedProducts);
  //   setselectedItems(updatedProducts);
  //   updateLocalStorage(updatedProducts);
  // };
  useEffect(() => {
    if (!product?.selectedQty) {
      setProduct((prev) => ({
        ...prev,
        selectedQty: 1,
      }));
    }
  }, [product]);
  const handleDecreaseQty = () => {
    if (product.selectedQty == 1) {
    } else {
      setProduct((prev) => ({
        ...prev,
        selectedQty: prev.selectedQty - 1,
      }));
    }
  };
  const HandleIncresemint = (handleIncreaseQty) => {
    if (parseFloat(handleIncreaseQty) == product.selectedQty) {
    } else {
      setProduct((prev) => ({
        ...prev,
        selectedQty: prev.selectedQty + 1,
      }));
    }
  };
  return (
    <>
      {domainInfo?.topNavbar && (
        <AnnouncementBar
          note={domainInfo?.topNavbarNote}
          mobile={domainInfo?.topNavbarMobile}
          email={domainInfo?.topNavbarEmail}
        />
      )}
      <Navbar domain={domain} logo={domainInfo?.logo} />
      <Banner lable={name} />
      <div className="product_main_container">
        <motion.div
          className="container_box"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.3, ease: "easeOut" }}
        >
          <img
            src={product?.image ?? Placeholder}
            alt="Product"
            width="600px"
            height="600px"
          />
        </motion.div>
        {product?.itemname ? (
          <motion.div
            className="container_box add_main_box"
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.3, ease: "easeOut" }}
          >
            <h1 className="product_main_heading">{product?.itemname}</h1>
            <h1 className="product_main_heading pt-3 amout_size">
              Rs {selectedPrice || ""}
            </h1>
            <p className="product_text_title">{product?.description}</p>

            {product?.variants?.map((variant, index) => {
              return variant?.variantOptionName?.toLowerCase() === "color" ? (
                <div className="color_selector mt-4" key={index}>
                  <label htmlFor="color" className="selec_color">
                    Select Color:{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                        fontSize: 16,
                      }}
                    >
                      {selectedVatiant}
                    </span>
                  </label>
                  <div
                    className="color_options ml-2"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {variant?.variantValues?.map((color, index) => (
                      <span
                        key={index}
                        className={`color_circle ${
                          selectedVatiant === color?.label ? "active" : ""
                        }`}
                        onClick={() => handleChangeVariant(color)}
                        style={{
                          backgroundColor: color?.label, // Assuming color names match CSS color names
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          border: "2px solid #ccc", // Optional: border for better visibility
                        }}
                      ></span>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="size_selector mt-5">
                  <label htmlFor="size" style={{ fontSize: 14 }}>
                    Select:{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                        fontSize: 16,
                      }}
                    >
                      {selectedVatiant}
                    </span>
                  </label>
                  <div
                    className="size_options ml-2"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "5px",
                      marginTop: "10px",
                    }}
                  >
                    {variant?.variantValues?.map((size, index) => (
                      <span
                        key={index}
                        onClick={() => handleChangeVariant(size)}
                        className={`size_box ${
                          selectedVatiant === size?.label ? "active" : ""
                        }`}
                        style={{
                          padding: "5px 10px", // Smaller padding for smaller boxes
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          width: "38px",
                          textAlign: "center",
                          cursor: "pointer",
                          backgroundColor:
                            selectedVatiant === size?.label
                              ? "#f0f0f0"
                              : "white",
                          fontSize: "14px", // Smaller font size
                        }}
                      >
                        {size?.label?.charAt(0)}
                      </span>
                    ))}
                  </div>
                </div>
              );
            })}

            {/* {product?.variants. <div>
                <td className="product_add">
                  <span className="add_product_text">
                    <span
                      onClick={() => {
                        handleDecreaseQty();
                      }}
                    >
                      -
                    </span>{" "}
                    {product?.selectedQty ?? "0"}{" "}
                    <span
                      onClick={() => {
                        HandleIncresemint();
                      }}
                    >
                      +
                    </span>
                  </span>
                </td>
              </div>:product?.count > 0 ? (
              <div>
                <td className="product_add">
                  <span className="add_product_text">
                    <span
                      onClick={() => {
                        handleDecreaseQty();
                      }}
                    >
                      -
                    </span>{" "}
                    {product?.selectedQty ?? "0"}{" "}
                    <span
                      onClick={() => {
                        HandleIncresemint();
                      }}
                    >
                      +
                    </span>
                  </span>
                </td>
              </div>
            ) : (
              <div>Stock Not Available</div>
            )} */}
            {product?.combinations?.filter(
              (e) => e.variantName == selectedVatiant
            )?.length > 0 ? (
              <div style={{ marginTop: "35px" }}>
                {product?.combinations
                  ?.filter((e) => e.variantName == selectedVatiant)
                  .map((variant, index) => (
                    <>
                      {variant?.count > 0 ? (
                        <div key={index}>
                          <td className="product_add">
                            <span className="add_product_text">
                              <span
                                onClick={() => {
                                  handleDecreaseQty();
                                }}
                              >
                                -
                              </span>
                              {product?.selectedQty ?? "0"}
                              <span
                                onClick={() => {
                                  HandleIncresemint(variant?.count);
                                }}
                              >
                                +
                              </span>
                            </span>
                          </td>
                        </div>
                      ) : (
                        <div>No Stock Available</div>
                      )}
                    </>
                  ))}
              </div>
            ) : (
              <>
                {product?.count > 0 ? (
                  <div>
                    <td className="product_add">
                      <span className="add_product_text">
                        <span
                          onClick={() => {
                            handleDecreaseQty();
                          }}
                        >
                          -
                        </span>
                        {product?.selectedQty ?? "0"}
                        <span
                          onClick={() => {
                            HandleIncresemint(product.count);
                          }}
                        >
                          +
                        </span>
                      </span>
                    </td>
                  </div>
                ) : (
                  <div>No Stock Available</div>
                )}
              </>
            )}

            <div className="mt-5 d-flex w-100">
              {/* <div>
                {product?.count > 0 && (
                  <button onClick={handleAddCart} className="add_cart_btn">
                    {isInCart ? "Added" : "Add To Cart"}
                  </button>
                )}
              </div> */}
              {product?.combinations?.filter(
                (e) => e.variantName == selectedVatiant
              )?.length > 0 ? (
                <div style={{ marginTop: "35px" }}>
                  {product?.combinations
                    ?.filter((e) => e.variantName == selectedVatiant)
                    .map((variant, index) => (
                      <>
                        {variant?.count > 0 ? (
                          <div>
                            <button
                              onClick={handleAddCart}
                              className="add_cart_btn"
                            >
                              {isInCart ? "Added" : "Add To Cart"}
                            </button>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </>
                    ))}
                </div>
              ) : (
                <>
                  {product?.count > 0 ? (
                    <div>
                      <div>
                        <button
                          onClick={handleAddCart}
                          className="add_cart_btn"
                        >
                          {isInCart ? "Added" : "Add To Cart"}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </>
              )}
              <div></div>
            </div>
          </motion.div>
        ) : (
          <ProductLoader />
        )}
      </div>
      <BestProduct initialVisible={3} products={products} domain={domain} />
      <Footer
        credit={domainInfo?.footerCredit}
        logo={domainInfo?.footerLogo}
        socialMedia={domainInfo?.socialMedia}
        isSocialMedia={domainInfo?.isSocialMedia}
      />
      <OpenCart domain={domain} initialProducts={updatedPorduct} />
      {domainInfo?.isWhatsappMessageAllowed && domainInfo?.whatsapp && (
        <WhatsappBtn number={domainInfo?.whatsapp} />
      )}
    </>
  );
}

export default Product;
