import React from 'react'
import './style.css'
function Count() {
    return (
        <div className='main_count'>
            <div className='sec_out compelet__screen_perfect'>
                <div>
                    <h3 className='num_set'>+500</h3>
                    <h3 className='text_set'>Clients</h3>
                </div>
                <div>
                    <h3 className='num_set'>+750</h3>
                    <h3 className='text_set'>Active Stores</h3>
                </div>
                <div>
                    <h3 className='num_set'>+2200</h3>
                    <h3 className='text_set'>Transactions Processed</h3>
                </div>
                <div>
                    <h3 className='num_set'>+65</h3>
                    <h3 className='text_set'>Features Integrated</h3>
                </div>
            </div>
        </div>
    )
}

export default Count