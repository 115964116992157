import React from 'react'
import './styles.css'
function Loader() {
    return (
        <div class="spring-loader">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
        </div>
    )
}

export default Loader;