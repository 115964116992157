import React from "react";
import { Link } from "react-router-dom";
import BestProductCard from "../Cards/BestProductCard";
import { createSlug } from "../../../../helper";

const DropdownDesktop = ({ categoryList }) => {
  return (
    <div className="mega-menu">
      <div className="inner-mega-menu">
        <div className="list-section">
          <div className="list-item">
            <ul>
              {categoryList.map((i) => (
                <Link
                  to={`/category/${createSlug(i?.category)}`}
                  className="mega-heading"
                >
                  <li>{i?.category}</li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownDesktop;
