import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "../View/Home/Screens/AboutUs";
import Cart from "../View/StorePage/Screens/Cart";
import CheckOut from "../View/StorePage/Screens/CheckOut";
import Home from "../View/Home";
import Features from "../View/Home/Screens/Features";
import MainHome from "../View/StorePage";
import Product from "../View/StorePage/Screens/Product";
import ThanksOrder from "../View/StorePage/Screens/ThankYou";
import PageNotFound from "../View/PageNotFound";
import PricingPlans from "../View/Home/Screens/PricingPlans";
import PrivacyPolicy from "../View/Home/Screens/PrivacyPolicy";
import RefundPolicy from "../View/Home/Screens/RefundPolicy";
import Register from "../View/Home/Screens/Register";
import ServicePolicy from "../View/Home/Screens/ServicePolicy";
import Shop from "../View/StorePage/Screens/Shop";
import Category from "../View/StorePage/Screens/Category";
import TermsAndConditions from "../View/Home/Screens/TermsAndConditions";
import { fetchDomain } from "../helper";
import { domainList } from "../constant";

function AppRoute() {
  const domain = fetchDomain();
  return (
    <>
      <BrowserRouter>
        <Routes>
          {!domainList.includes(domain) ? (
            <>
              <Route path="/" element={<MainHome domain={domain} />} />
              <Route path="/cart" element={<Cart domain={domain} />} />
              <Route path="/checkout" element={<CheckOut domain={domain} />} />
              <Route
                path="/thank-you/:orderNumber"
                element={<ThanksOrder domain={domain} />}
              />
              <Route
                path="/product/:title"
                element={<Product domain={domain} />}
              />
              <Route path="/shop" element={<Shop domain={domain} />} />
              <Route
                path="/category/:title"
                element={<Category domain={domain} />}
              />
            </>
          ) : (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/service-policy" element={<ServicePolicy />} />
              <Route path="/refund-policy" element={<RefundPolicy />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/pricing-Plans" element={<PricingPlans />} />
              <Route path="/features" element={<Features />} />
            </>
          )}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default AppRoute;
