import React from "react";
import "./RightSideModal.css"; // Custom CSS for modal styling
import { Icon } from "@iconify/react";

const RightSideModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="right-side-modal-overlay" onClick={onClose}>
      <div
        className="right-side-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="right-side-modal-close" onClick={onClose}>
          <Icon icon={"iconamoon:close-bold"} fontSize="18px" color="white" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default RightSideModal;
