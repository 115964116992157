import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { createSlug } from "../../../../helper";
const DropdownMobile = ({ categoryList, title, link, dropdown }) => {
  return (
    <div className="mobile-drop">
      {dropdown ? (
        <Accordion>
          <Accordion.Item eventKey={title}>
            <Accordion.Header>
              <Link to={link}>{title}</Link>
            </Accordion.Header>
            <Accordion.Body>
              {categoryList?.map((i) => (
                <Accordion className="mobile-sub-drop">
                  <Accordion.Item eventKey={i.id}>
                    <Link
                      className="sub-tab"
                      to={`/category/${createSlug(i?.category)}`}
                    >
                      {i.category}
                    </Link>
                  </Accordion.Item>
                </Accordion>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <Link to={link} className="tab">
          {title}
        </Link>
      )}
    </div>
  );
};

export default DropdownMobile;
