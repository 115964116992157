import React from 'react'
import './style.css'
import mobile from '../../../../asesst/mockup.png'
import Logo from '../../../../asesst/appstore.png'
function ComingSoonApp() {
    return (
        <div className='compelet__screen_perfect main__box_comingsonn'>
            <div className='coming_width'>
                <h3 className='sec_comming_head'>
                    Download the Tijarah Soft Mobile App
                </h3>
                <p className='coming_pera'>Manage your business on the go! Stay connected, track sales, and streamline operations with the Tijarah Soft app available now for download.</p>
                <a target='_blank' href='https://play.google.com/store/apps/details?id=com.tijarahsoft'><img src={Logo} width="150px" /></a>
            </div>
            <div className='coming_width'>
                <img src={mobile} className='mobile_img' alt='' />
            </div>
        </div>

    )
}

export default ComingSoonApp