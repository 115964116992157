// import React from 'react';
// import CustomNavbar from '../../Component/Navber';
// import LetsStart from '../../Component/LetsStart';
// import Footer from '../../Component/Footer';
// import './style.css'
// function Features() {

//     const firstCard = [
//         {
//             img: 'https://cdn-icons-png.flaticon.com/512/11600/11600121.png',
//             heading: 'Quick Checkout',
//             text: 'Speed up transactions with fast and reliable checkout processes'
//         },
//         {
//             img: 'https://tse2.mm.bing.net/th?id=OIP.qe7D7I2eQgKh9cH0IHiwXQHaHa&pid=Api&P=0&h=220',
//             heading: 'Multiple Payment Methods',
//             text: 'Accept cash, credit cards, mobile payments, and more.'
//         },
//         {
//             img: 'https://tse4.mm.bing.net/th?id=OIP.mebo18FfLppi0dzQaPTMpAHaHa&pid=Api&P=0&h=220',
//             heading: 'Sales Reporting',
//             text: 'Gain insights into your sales data with detailed reports.'
//         },

//     ]
//     const Inventry = [
//         {
//             img: 'https://tse2.mm.bing.net/th?id=OIP.8wo85_JKMZZkGST3ViuqCAAAAA&pid=Api&P=0&h=220',
//             heading: 'Real-Time Inventory Tracking:',
//             text: 'Know what  in stock at all times.'
//         },
//         {
//             img: 'https://tse4.mm.bing.net/th?id=OIP.FEtse1_WK9yLfasH7AnZcAHaHa&pid=Api&P=0&h=220',
//             heading: 'Automated Reordering',
//             text: 'Set thresholds to automatically reorder products'
//         },
//         {
//             img: 'https://tse2.mm.bing.net/th?id=OIP.9EQ3X0d9BqUfyD85jVW2hgHaHa&pid=Api&P=0&h=220',
//             heading: 'Inventory Reports',
//             text: 'Analyze stock levels, turnover rates, and more.'
//         },

//     ]
//     const Customer = [
//         {
//             img: 'https://tse2.mm.bing.net/th?id=OIP.kCNMzRvb-QmjpvH1UPWUDQHaHa&pid=Api&P=0&h=220',
//             heading: 'Customer Profiles',
//             text: 'Store detailed customer information and preferences.'
//         },
//         {
//             img: 'https://tse4.mm.bing.net/th?id=OIP.TQvCMItJiz34ahW0wBqWIgHaHa&pid=Api&P=0&h=220',
//             heading: 'Purchase History',
//             text: 'Track past transactions to offer personalized service.'
//         },
//         {
//             img: 'https://tse4.mm.bing.net/th?id=OIP.7dkRsfKFKHR8u_NipbBAwQHaF_&pid=Api&P=0&h=220',
//             heading: 'Loyalty Programs',
//             text: 'Reward your customers and encourage repeat purchases.'
//         },

//     ]
//     const MultiStore = [
//         {
//             img: 'https://tse1.mm.bing.net/th?id=OIP.cz_FqnMWSa_ZyyWwOqCktQHaFb&pid=Api&P=0&h=220',
//             heading: 'Centralized Control',
//             text: 'Manage all your stores from one dashboard.'
//         },
//         {
//             img: 'https://tse4.mm.bing.net/th?id=OIP.oHGfK68tJVN9V5N41_uprQHaHa&pid=Api&P=0&h=220',
//             heading: 'Inventory Transfers',
//             text: ' Move stock between stores effortlessly'
//         },
//         {
//             img: 'https://tse4.mm.bing.net/th?id=OIP.by00IW_HQLwYqSJObM95QQHaHa&pid=Api&P=0&h=220',
//             heading: 'Store-Specific Reports',
//             text: 'Analyze performance at each location.'
//         },

//     ]
//     const Accounting = [
//         {
//             img: 'https://tse1.mm.bing.net/th?id=OIP.O_5St6_m_HAJRWp8T0khUwHaHa&pid=Api&P=0&h=220',
//             heading: 'Expense Tracking',
//             text: ' Monitor and categorize business expenses.'
//         },
//         {
//             img: 'https://tse2.mm.bing.net/th?id=OIP.YWR9R-yw57nZ7JBvAGtTcQHaHa&pid=Api&P=0&h=220',
//             heading: 'Invoice Management',
//             text: 'Create, send, and track invoices with ease.'
//         },
//         {
//             img: 'https://tse1.mm.bing.net/th?id=OIP.eEFDAFOoGSTheeyMWNNP_gHaHC&pid=Api&P=0&h=220',
//             heading: 'Financial Reports',
//             text: ' Generate balance sheets, profit and loss statements, and more'
//         },

//     ]
//     const UserAccess = [
//         {
//             img: 'https://tse2.mm.bing.net/th?id=OIP.eu-hU8q8yYuzTn69j4k_BwHaJ8&pid=Api&P=0&h=220',
//             heading: 'Role-Based Access',
//             text: '  Define roles with specific permissions.'
//         },
//         {
//             img: 'https://tse2.mm.bing.net/th?id=OIP.52lkZsi7uqQgnSC3s5DC3gHaHw&pid=Api&P=0&h=220',
//             heading: 'Activity Logs',
//             text: 'Monitor user activity for accountability'
//         },
//         {
//             img: 'https://tse1.mm.bing.net/th?id=OIP.MVBPormpv5X5gTYJiJLl6AHaH6&pid=Api&P=0&h=220',
//             heading: 'Secure Login',
//             text: ' Protect your data with secure authentication methods.'
//         },

//     ]
//     const OfflineMode = [
//         {
//             img: 'https://tse2.mm.bing.net/th?id=OIP.ac5bYDQnMNgGs-TuNdeykwHaHa&pid=Api&P=0&h=220',
//             heading: 'Seamless Offline Operation',
//             text: '   Continue working without internet access.'
//         },
//         {
//             img: 'https://tse4.mm.bing.net/th?id=OIP.wwLvEma5BRcynbjql_L97gHaHa&pid=Api&P=0&h=220',
//             heading: 'Automatic Syncing',
//             text: 'Sync data automatically when youre back online.'
//         },
//         {
//             img: 'https://tse1.mm.bing.net/th?id=OIP.ZkdhHUiBblF4HuTmMOr8bQHaHa&pid=Api&P=0&h=220',
//             heading: 'Reliable Performance',
//             text: 'Ensure smooth operations no matter the connectivity.'
//         },

//     ]
//     const Customizable = [
//         {
//             img: 'https://tse2.mm.bing.net/th?id=OIP.1Y6v0WgHgE6mBsv8FUwF0gHaHa&pid=Api&P=0&h=220',
//             heading: 'Real-Time Analytics',
//             text: '  Access up-to-date information at a glance.'
//         },
//         {
//             img: 'https://tse3.mm.bing.net/th?id=OIP.pl2cjJcSJVY9L8U9UeUGNQHaHa&pid=Api&P=0&h=220',
//             heading: 'Custom Widgets',
//             text: 'Tailor your dashboard to display the data that matters most.'
//         },
//         {
//             img: 'https://tse1.mm.bing.net/th?id=OIP.hVlC8mzfdZK9J5v6VyfBMQHaHa&pid=Api&P=0&h=220',
//             heading: 'Visual Reports',
//             text: 'Make informed decisions with easy-to-understand charts and graphs.'
//         },

//     ]
//     const Comprehensive = [
//         {
//             img: 'https://tse2.mm.bing.net/th?id=OIP.BBLVuehbPXFlbRVs-cl7PQHaHa&pid=Api&P=0&h=220',
//             heading: 'Sales Reports',
//             text: '  Analyze trends, top-selling products, and revenue.'
//         },
//         {
//             img: 'https://tse4.mm.bing.net/th?id=OIP.JwbjyXSdhOHAGvh_lPzdaAHaHa&pid=Api&P=0&h=220',
//             heading: 'Inventory Reports',
//             text: ' Track stock levels, reorder points, and inventory turnover.'
//         },
//         {
//             img: 'https://tse1.mm.bing.net/th?id=OIP.p7kb2EPWL8rLryP5_HYobwHaHa&pid=Api&P=0&h=220',
//             heading: 'Financial Reports',
//             text: 'Monitor your business s financial health with ease.'
//         },

//     ]
//     const Secure = [
//         {
//             img: 'https://tse4.mm.bing.net/th?id=OIP.ASbfxpxdZANyvNCVIDyPMwHaHa&pid=Api&P=0&h=220',
//             heading: 'Automated Backups',
//             text: '   Schedule regular backups to the cloud.'
//         },
//         {
//             img: 'https://tse3.mm.bing.net/th?id=OIP.B2whT_oVVgDoCvzRfdraBAHaHa&pid=Api&P=0&h=220',
//             heading: 'Data Encryption',
//             text: '  Safeguard your data with industry-standard encryption.'
//         },
//         {
//             img: 'https://tse4.mm.bing.net/th?id=OIP.-7jQSxoQqSnsXKAQYtHwwgHaFj&pid=Api&P=0&h=220',
//             heading: 'Easy Recovery',
//             text: 'Restore your data quickly and easily when needed.'
//         },

//     ]
//     const Scalable = [
//         {
//             img: 'https://tse3.mm.bing.net/th?id=OIP.yhTaOf9QK-utAmvmT8wK6wHaHa&pid=Api&P=0&h=220',
//             heading: 'Flexible Plans',
//             text: '   Choose the plan that fits your business needs.'
//         },
//         {
//             img: 'https://tse3.mm.bing.net/th?id=OIP.48pFB2Y98xKSOhhzr2_stAHaHa&pid=Api&P=0&h=220',
//             heading: 'User Management',
//             text: '  Easily add or remove users as your team grows.'
//         },
//         {
//             img: 'https://tse4.mm.bing.net/th?id=OIP.pP60uzxjxvpJjVqDSykRaQHaHa&pid=Api&P=0&h=220',
//             heading: 'Modular Features',
//             text: 'Add or remove features based on your business requirements.'
//         },

//     ]
//     const CustomerSupport = [
//         {
//             img: 'https://tse1.mm.bing.net/th?id=OIP.YrxDbK6wF9gmy42aJLWNTwHaHa&pid=Api&P=0&h=220',
//             heading: 'Dedicated Support Team',
//             text: '   Get help from knowledgeable professionals.'
//         },
//         {
//             img: 'https://tse2.mm.bing.net/th?id=OIP.RV3zbNLQ6P8ZpsLbTwx8HAHaHa&pid=Api&P=0&h=220',
//             heading: 'Multiple Support Channels',
//             text: '   Reach us via phone, email, or live chat.'
//         },
//         {
//             img: 'https://tse2.mm.bing.net/th?id=OIP.5kf1iG2dRJjjUW6X4fznkQAAAA&pid=Api&P=0&h=220',
//             heading: 'Comprehensive Help Cente',
//             text: 'Access tutorials, guides, and FAQs anytime.'
//         },

//     ]


//     return (
//         <>
//             <CustomNavbar />
//             <div className='about__sub'>
//                 <div className='w-100'>
//                     <h1 className='about_heading text-white' >Features</h1>
//                     <a href='/' className='home_link'>Home</a>   <span className='slash_tyle'>/</span>  <a className='about_link' href='/features'>Features</a>
//                     {/* Welcome to Tijarah Soft, where innovation meets simplicity in business management. We are a dynamic team of professionals dedicated to empowering businesses with the tools they need to thrive in today's competitive landscape. Our mission is to simplify business operations through cutting-edge software solutions that are easy to use, reliable, and tailored to meet the unique needs of our customers. */}
//                 </div>
//             </div>
//             <div className='feature_main_box'>
//                 <h2 className='feature_head'>Point of Sale (POS) System</h2>
//                 <p className='text-dark'>Our intuitive POS system is at the heart of Tijarah Soft, enabling you to process sales quickly and efficiently. With an easy-to-use interface, real-time transaction tracking, and integrated payment options, you can manage sales seamlessly, whether you're in-store or on the go.</p>
//                 <div className='sub_box_feature my-5'>
//                     {firstCard.map((e, i) => (
//                         <div className='box_cards features_box_card'>
//                             <p className='text-center'>
//                                 <img src={e.img} width='50px' />
//                             </p>
//                             <h2 className='choose_heading'>{e.heading}</h2>
//                             <p className='text_cards'>{e.text}</p>
//                         </div>
//                     ))}



//                 </div>
//             </div>
//             <div className='feature_main_box'>
//                 <h2 className='feature_head'>Inventory Management</h2>
//                 <p className='text-dark'>Keep track of your stock levels effortlessly with Tijarah Soft's robust inventory management features. Monitor inventory in real time, automate reordering, and reduce stockouts to ensure you always have the products your customers want.</p>
//                 <div className='sub_box_feature my-5'>
//                     {Inventry.map((e, i) => (
//                         <div className='box_cards features_box_card'>
//                             <p className='text-center'>
//                                 <img src={e.img} width='50px' />
//                             </p>
//                             <h2 className='choose_heading'>{e.heading}</h2>
//                             <p className='text_cards'>{e.text}</p>
//                         </div>
//                     ))}


//                 </div>
//             </div>
//             <div className='feature_main_box'>
//                 <h2 className='feature_head'>Customer Relationship Management (CRM)</h2>
//                 <p className='text-dark'>Build and maintain strong relationships with your customers using our integrated CRM tools. Manage customer profiles, track purchase history, and tailor your marketing efforts to improve customer loyalty and drive repeat business.</p>
//                 <div className='sub_box_feature my-5'>

//                     {
//                         Customer.map((e, i) => (
//                             <div className='box_cards features_box_card'>
//                                 <p className='text-center'>
//                                     <img src={e.img} width='50px' />
//                                 </p>
//                                 <h2 className='choose_heading'>{e.heading}</h2>
//                                 <p className='text_cards'>{e.text}</p>
//                             </div>
//                         ))
//                     }


//                 </div>
//             </div>
//             <div className='feature_main_box'>
//                 <h2 className='feature_head'>Multi-Store Management</h2>
//                 <p className='text-dark'>Manage multiple stores from a single platform with ease. Tijarah Soft allows you to oversee operations, track sales, and manage inventory across all your locations, ensuring consistency and efficiency.</p>
//                 <div className='sub_box_feature my-5'>

//                     {
//                         MultiStore.map((e, i) => (
//                             <div className='box_cards features_box_card'>
//                                 <p className='text-center'>
//                                     <img src={e.img} width='50px' />
//                                 </p>
//                                 <h2 className='choose_heading'>{e.heading}</h2>
//                                 <p className='text_cards'>{e.text}</p>
//                             </div>
//                         ))
//                     }


//                 </div>
//             </div>
//             <div className='feature_main_box'>
//                 <h2 className='feature_head'>Accounting and Financial Management</h2>
//                 <p className='text-dark'>Stay on top of your finances with Tijarah Soft's built-in accounting features. Track expenses, manage invoices, and generate financial reports to keep your business financially healthy and compliant.</p>
//                 <div className='sub_box_feature my-5'>

//                     {
//                         Accounting.map((e, i) => (
//                             <div className='box_cards features_box_card'>
//                                 <p className='text-center'>
//                                     <img src={e.img} width='50px' />
//                                 </p>
//                                 <h2 className='choose_heading'>{e.heading}</h2>
//                                 <p className='text_cards'>{e.text}</p>
//                             </div>
//                         ))
//                     }


//                 </div>
//             </div>
//             <div className='feature_main_box'>
//                 <h2 className='feature_head'>User Access Control</h2>
//                 <p className='text-dark'>Maintain security and control over your business operations with customizable user access levels. Assign roles and permissions to ensure that your team members have the appropriate access to the tools they need.</p>
//                 <div className='sub_box_feature my-5'>

//                     {
//                         UserAccess.map((e, i) => (
//                             <div className='box_cards features_box_card'>
//                                 <p className='text-center'>
//                                     <img src={e.img} width='50px' />
//                                 </p>
//                                 <h2 className='choose_heading'>{e.heading}</h2>
//                                 <p className='text_cards'>{e.text}</p>
//                             </div>
//                         ))
//                     }


//                 </div>
//             </div>
//             <div className='feature_main_box'>
//                 <h2 className='feature_head'>Offline Mode</h2>
//                 <p className='text-dark'>Tijarah Soft ensures uninterrupted business operations, even when the internet is down. Our offline mode allows you to continue processing transactions and managing inventory, with automatic syncing when the connection is restored.</p>
//                 <div className='sub_box_feature my-5'>

//                     {
//                         OfflineMode.map((e, i) => (
//                             <div className='box_cards features_box_card' >
//                                 <p className='text-center'>
//                                     <img src={e.img} width='50px' />
//                                 </p>
//                                 <h2 className='choose_heading'>{e.heading}</h2>
//                                 <p className='text_cards'>{e.text}</p>
//                             </div>
//                         ))
//                     }


//                 </div>
//             </div>
//             <div className='feature_main_box'>
//                 <h2 className='feature_head'>Customizable Dashboards</h2>
//                 <p className='text-dark'>Get a clear overview of your business with customizable dashboards. Track key metrics, monitor sales, and stay informed with real-time data presented in a way that makes sense to you.</p>
//                 <div className='sub_box_feature my-5'>

//                     {
//                         Customizable.map((e, i) => (
//                             <div className='box_cards features_box_card'>
//                                 <p className='text-center'>
//                                     <img src={e.img} width='50px' />
//                                 </p>
//                                 <h2 className='choose_heading'>{e.heading}</h2>
//                                 <p className='text_cards'>{e.text}</p>
//                             </div>
//                         ))
//                     }


//                 </div>
//             </div>

//             <div className='feature_main_box'>
//                 <h2 className='feature_head'>Comprehensive Reporting</h2>
//                 <p className='text-dark'>Make data-driven decisions with Tijarah Soft's powerful reporting tools. Generate detailed reports on sales, inventory, customer behavior, and financial performance to gain insights that drive your business forward.</p>
//                 <div className='sub_box_feature my-5'>

//                     {
//                         Comprehensive.map((e, i) => (
//                             <div className='box_cards  features_box_card'>
//                                 <p className='text-center'>
//                                     <img src={e.img} width='50px' />
//                                 </p>
//                                 <h2 className='choose_heading'>{e.heading}</h2>
//                                 <p className='text_cards'>{e.text}</p>
//                             </div>
//                         ))
//                     }


//                 </div>
//             </div>

//             <div className='feature_main_box'>
//                 <h2 className='feature_head'>Secure Cloud Backup</h2>
//                 <p className='text-dark'>Protect your business data with Tijarah Soft's secure cloud backup solution. Ensure your information is safe, accessible, and recoverable in the event of hardware failure or other disruptions.</p>
//                 <div className='sub_box_feature my-5'>
//                     {
//                         Secure.map((e, i) => (
//                             <div className='box_cards  features_box_card'>
//                                 <p className='text-center'>
//                                     <img src={e.img} width='50px' />
//                                 </p>
//                                 <h2 className='choose_heading'>{e.heading}</h2>
//                                 <p className='text_cards'>{e.text}</p>
//                             </div>
//                         ))
//                     }


//                 </div>
//             </div>
//             <div className='feature_main_box'>
//                 <h2 className='feature_head'>Scalable Solutions</h2>
//                 <p className='text-dark'>Whether you're a small business or growing enterprise, Tijarah Soft scales with you. Customize features, add users, and expand your operations effortlessly as your business evolves.</p>
//                 <div className='sub_box_feature my-5'>
//                     {
//                         Scalable.map((e, i) => (
//                             <div className='box_cards  features_box_card'>
//                                 <p className='text-center'>
//                                     <img src={e.img} width='50px' />
//                                 </p>
//                                 <h2 className='choose_heading'>{e.heading}</h2>
//                                 <p className='text_cards'>{e.text}</p>
//                             </div>
//                         ))
//                     }


//                 </div>
//             </div>

//             <div className='feature_main_box'>
//                 <h2 className='feature_head'>24/7 Customer Support</h2>
//                 <p className='text-dark'>We understand that your business doesn't stop, and neither do we. Tijarah Soft offers 24/7 customer support to assist you whenever you need help, ensuring that any issues are resolved quickly and efficiently..</p>
//                 <div className='sub_box_feature my-5'>
//                     {
//                         CustomerSupport.map((e, i) => (
//                             <div className='box_cards  features_box_card'>
//                                 <p className='text-center'>
//                                     <img src={e.img} width='50px' />
//                                 </p>
//                                 <h2 className='choose_heading'>{e.heading}</h2>
//                                 <p className='text_cards'>{e.text}</p>
//                             </div>
//                         ))
//                     }


//                 </div>
//             </div>


//             <div className='content_container'>
//                 {/* <p className='content'>
//                     Tijarah Soft is designed to streamline your business operations with a comprehensive suite of powerful, user-friendly features. Whether you're managing inventory, handling sales, or analyzing business performance, Tijarah Soft provides the tools you need to succeed.
//                 </p> */}



//                 <p className='content'>
//                     Experience the power of Tijarah Soft and take your business to the next level. Whether you're a small shop or a growing enterprise, our software provides the tools you need to streamline operations, increase productivity, and achieve your business goals. Start today and see how Tijarah Soft can transform the way you work.
//                 </p>
//             </div>
//             <LetsStart />
//             <Footer />
//         </>
//     )
// }

// export default Features;

// // import React, { useState } from 'react';
// // import './CustomTabs.css'; // Import your CSS file for styling
// // import CustomNavbar from '../../Component/Navber';
// // import LetsStart from '../../Component/LetsStart';
// // import Footer from '../../Component/Footer';
// // import './style.css';

// // const Features = () => {
// //     const [activeTab, setActiveTab] = useState(0);

// //     // Define the tabs with headings, content text, and card data
// //     const tabs = [
// //         {
// //             label: 'POS',
// //             heading: 'Point of Sale (POS) System',
// //             text: 'Our intuitive POS system is at the heart of Tijarah Soft, enabling you to process sales quickly and efficiently. With an easy-to-use interface, real-time transaction tracking, and integrated payment options, you can manage sales seamlessly, whether you re in-store or on the go.',
// //             cards: [
// //                 {
// //                     title: 'Quick Checkout', description: 'Speed up transactions with fast and reliable checkout processes.', img: 'https://cdn-icons-png.flaticon.com/512/11600/11600121.png',
// //                 },
// //                 { title: 'Multiple Payment Methods', description: 'Accept cash, credit cards, mobile payments, and more.', img: 'https://tse2.mm.bing.net/th?id=OIP.qe7D7I2eQgKh9cH0IHiwXQHaHa&pid=Api&P=0&h=220', },
// //                 { title: 'Sales Reporting', description: 'Gain insights into your sales data with detailed reports.', img: 'https://up.yimg.com/ib/th?id=OIP.I10BPZakhi3MjCP_n_edmAHaHa&pid=Api&rs=1&c=1&qlt=95&w=115&h=115', },
// //             ],
// //         },
// //         {
// //             label: 'Inventory',
// //             heading: 'Inventory Management',
// //             text: 'Keep track of your stock levels effortlessly with Tijarah Softs robust inventory management features. Monitor inventory in real time, automate reordering, and reduce stockouts to ensure you always have the products your customers want.',
// //             cards: [
// //                 { title: 'Real-Time Inventory Tracking', description: 'Know what s in stock at all times.', img: 'https://up.yimg.com/ib/th?id=OIP.IW8zkZwKb8nByPoQ7xNlqAHaHa&pid=Api&rs=1&c=1&qlt=95&w=99&h=99' },
// //                 { title: 'Automated Reordering', description: ' Set thresholds to automatically reorder products.', img: 'https://up.yimg.com/ib/th?id=OIP.3UL669KnCcsx3hnUjp88YQAAAA&pid=Api&rs=1&c=1&qlt=95&w=124&h=108' },
// //                 { title: 'Inventory Reports', description: 'Analyze stock levels, turnover rates, and more.', img: 'https://tse4.mm.bing.net/th?id=OIP.zdQSQuJcxFvr7TppLbz6XQHaHa&pid=Api&P=0&h=220' },
// //             ],
// //         },
// //         {
// //             label: 'Customer',
// //             heading: 'Customer Relationship Management (CRM)',
// //             text: 'Build and maintain strong relationships with your customers using our integrated CRM tools. Manage customer profiles, track purchase history, and tailor your marketing efforts to improve customer loyalty and drive repeat business.',
// //             cards: [
// //                 { title: 'Customer Profiles', description: 'Store detailed customer information and preferences.', img: "https://tse1.mm.bing.net/th?id=OIP.NKvWNojTth4Tlau5P2EUUgHaHa&pid=Api&P=0&h=220" },
// //                 { title: 'Purchase History', description: 'Track past transactions to offer personalized service.', img: 'https://tse1.mm.bing.net/th?id=OIP.XuoElOCUxrOi8d8mz8UN9AHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Loyalty Programs', description: 'Reward your customers and encourage repeat purchases.', img: 'https://tse1.mm.bing.net/th?id=OIP.lWfEqibUVACOHHr-8ILQRAHaHa&pid=Api&P=0&h=220' },
// //             ],
// //         },
// //         {
// //             label: 'Multi-Store',
// //             heading: 'Multi-Store Management',
// //             text: 'Manage multiple stores from a single platform with ease. Tijarah Soft allows you to oversee operations, track sales, and manage inventory across all your locations, ensuring consistency and efficiency.',
// //             cards: [
// //                 { title: 'Centralized Contro', description: ' Manage all your stores from one dashboard.', img: 'https://tse1.mm.bing.net/th?id=OIP.8ZWDFDSOEX7Kpgg7juockgHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Inventory Transfers', description: 'Move stock between stores effortlessly.', img: 'https://tse3.mm.bing.net/th?id=OIP.hbVJUoGWJ0lHq0AbPxnJqAHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Store-Specific Reports:', description: ' Analyze performance at each location.', img: 'https://tse4.mm.bing.net/th?id=OIP.j8xphNcl1VXQqCiv22_9vwHaHa&pid=Api&P=0&h=220' },
// //             ],
// //         },
// //         {
// //             label: 'Financial',
// //             heading: 'Accounting and Financial Management',
// //             text: 'Stay on top of your finances with Tijarah Softs built-in accounting features. Track expenses, manage invoices, and generate financial reports to keep your business financially healthy and compliant.',
// //             cards: [
// //                 { title: 'Expense Tracking', description: ' Monitor and categorize business expenses.', img: 'https://tse4.mm.bing.net/th?id=OIP.EMOYiw8KykTTz6DuckbntwHaJK&pid=Api&P=0&h=220' },
// //                 { title: 'Invoice Management', description: 'Create, send, and track invoices with ease.', img: 'https://tse2.mm.bing.net/th?id=OIP.YWR9R-yw57nZ7JBvAGtTcQHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Financial Reports', description: '  Generate balance sheets, profit and loss statements, and more', img: 'https://tse2.mm.bing.net/th?id=OIP.dSA7JfNvuQkZqW4iY19tRgHaHa&pid=Api&P=0&h=220' },
// //             ],
// //         },
// //         {
// //             label: 'User Access ',
// //             heading: 'User Access Control',
// //             text: 'Maintain security and control over your business operations with customizable user access levels. Assign roles and permissions to ensure that your team members have the appropriate access to the tools they need.',
// //             cards: [
// //                 { title: 'Role-Based Access', description: ' Define roles with specific permissions.', img: 'https://tse2.mm.bing.net/th?id=OIP.eu-hU8q8yYuzTn69j4k_BwHaJ8&pid=Api&P=0&h=220' },
// //                 { title: 'Activity Logs', description: 'Monitor user activity for accountability..', img: 'https://tse2.mm.bing.net/th?id=OIP.52lkZsi7uqQgnSC3s5DC3gHaHw&pid=Api&P=0&h=220' },
// //                 { title: 'Secure Login', description: ' Protect your data with secure authentication methods.', img: 'https://tse4.mm.bing.net/th?id=OIP.bcjSvq2t_Xjq3Rzi458AewHaHa&pid=Api&P=0&h=220' },
// //             ],
// //         },
// //         {
// //             label: 'Offline  ',
// //             heading: 'Offline Mode',
// //             text: 'Tijarah Soft ensures uninterrupted business operations, even when the internet is down. Our offline mode allows you to continue processing transactions and managing inventory, with automatic syncing when the connection is restored.',
// //             cards: [
// //                 { title: 'Seamless Offline Operation', description: ' Continue working without internet access..', img: 'https://tse2.mm.bing.net/th?id=OIP.t9OZF3p9iauqbbIM-CkPUgHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Automatic Syncing', description: 'Sync data automatically when you re back online.', img: 'https://tse3.mm.bing.net/th?id=OIP.SBGD_coZUVfvBu4Swlp07wHaHf&pid=Api&P=0&h=220' },
// //                 { title: 'Reliable Performance', description: ' Ensure smooth operations no matter the connectivity.', img: 'https://tse3.mm.bing.net/th?id=OIP.UDpx8IbArDZInhcJ26-cXwAAAA&pid=Api&P=0&h=220' },
// //             ],
// //         },
// //         {
// //             label: 'Customizable  ',
// //             heading: 'Customizable Dashboards',
// //             text: 'Get a clear overview of your business with customizable dashboards. Track key metrics, monitor sales, and stay informed with real-time data presented in a way that makes sense to you.  ',
// //             cards: [
// //                 { title: 'Real-Time Analytics', description: ' Access up-to-date information at a glance.', img: 'https://tse2.mm.bing.net/th?id=OIP.1Y6v0WgHgE6mBsv8FUwF0gHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Custom Widgets', description: 'Tailor your dashboard to display the data that matters most.', img: 'https://tse3.mm.bing.net/th?id=OIP.pl2cjJcSJVY9L8U9UeUGNQHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Visual Reports', description: 'Make informed decisions with easy-to-understand charts and graphs.', img: 'https://tse4.mm.bing.net/th?id=OIP.by00IW_HQLwYqSJObM95QQHaHa&pid=Api&P=0&h=220' },
// //             ],
// //         },
// //         {
// //             label: 'Comprehensive   ',
// //             heading: 'Comprehensive Reporting',
// //             text: 'Make data-driven decisions with Tijarah Soft s powerful reporting tools. Generate detailed reports on sales, inventory, customer behavior, and financial performance to gain insights that drive your business forward. ',
// //             cards: [
// //                 { title: 'Sales Reports', description: ' Analyze trends, top-selling products, and revenue..', img: 'https://tse2.mm.bing.net/th?id=OIP.BBLVuehbPXFlbRVs-cl7PQHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Inventory Reports', description: 'Track stock levels, reorder points, and inventory turnover.  ,', img: 'https://tse3.mm.bing.net/th?id=OIP.VEMgF-96XL6ZINBpKntjNgHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Financial Reports', description: 'Monitor your business s financial health with ease.', img: 'https://tse3.mm.bing.net/th?id=OIP.XaN29b7R2DVcOz_NbRzjYQHaHa&pid=Api&P=0&h=220' },
// //             ],
// //         },
// //         {
// //             label: 'Backup   ',
// //             heading: 'Secure Cloud Backup',
// //             text: 'Protect your business data with Tijarah Soft s secure cloud backup solution. Ensure your information is safe, accessible, and recoverable in the event of hardware failure or other disruptions. ',
// //             cards: [
// //                 { title: 'Automated Backups', description: 'Schedule regular backups to the cloud.', img: 'https://tse1.mm.bing.net/th?id=OIP.BTksYVevFhlp2_JNcjPBOwHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Data Encryption', description: 'Safeguard your data with industry-standard encryption..', img: 'https://tse1.mm.bing.net/th?id=OIP.tg_RwbMH9bLD2kXu2HYnWgHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Easy Recovery', description: 'Restore your data quickly and easily when needed.', img: 'https://tse4.mm.bing.net/th?id=OIP.-7jQSxoQqSnsXKAQYtHwwgHaFj&pid=Api&P=0&h=220' },
// //             ],
// //         },
// //         {
// //             label: 'Solutions   ',
// //             heading: 'Scalable Solutions',
// //             text: 'Whether you re a small business or growing enterprise, Tijarah Soft scales with you. Customize features, add users, and expand your operations effortlessly as your business evolves. ',
// //             cards: [
// //                 { title: 'Flexible Plans', description: ' Choose the plan that fits your business needs.', img: 'https://tse2.mm.bing.net/th?id=OIP.vKd_pXpZYtJZnk16AGEiewHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'User Management', description: 'Easily add or remove users as your team grows.', img: 'https://tse2.mm.bing.net/th?id=OIP.S_MhDSQ1yCrGd12lpowhhwHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Modular Features', description: 'Add or remove features based on your business requirements.', img: 'https://tse4.mm.bing.net/th?id=OIP.pP60uzxjxvpJjVqDSykRaQHaHa&pid=Api&P=0&h=220   ' },
// //             ],
// //         },
// //         {
// //             label: 'Support  ',
// //             heading: '24/7 Customer Support',
// //             text: 'We understand that your business doesn t stop, and neither do we. Tijarah Soft offers 24/7 customer support to assist you whenever you need help, ensuring that any issues are resolved quickly and efficiently. ',
// //             cards: [
// //                 { title: 'Dedicated Support Team', description: ' Get help from knowledgeable professionals.', img: 'https://tse1.mm.bing.net/th?id=OIP.YrxDbK6wF9gmy42aJLWNTwHaHa&pid=Api&P=0&h=220' },
// //                 { title: 'Multiple Support Channels', description: 'Reach us via phone, email, or live chat.', img: 'https://tse3.mm.bing.net/th?id=OIP.zEMOJL7zndnvXxnHd9BzdAAAAA&pid=Api&P=0&h=220' },
// //                 { title: 'Comprehensive Help Center', description: 'Access tutorials, guides, and FAQs anytime.', img: 'https://tse1.mm.bing.net/th?id=OIP.Ss2PKI5ZQu2Q2r7hpfNo5QHaHa&pid=Api&P=0&h=220' },
// //             ],
// //         },

// //     ];

// //     return (
// //         <>
// <CustomNavbar />
// <div className='about__sub'>
//     <div className='w-100'>
//         <h1 className='about_heading text-white'>Features</h1>
//         <a href='/' className='home_link'>Home</a>
//         <span className='slash_tyle'>/</span>
//         <a className='about_link' href='/features'>Features</a>
//     </div>
// </div>
// //             <div className="tabs">
// //                 <ul className="tab-list">
// //                     {tabs.map((tab, index) => (
// //                         <li
// //                             key={index}
// //                             className={`tab-item ${activeTab === index ? 'active' : ''}`}
// //                             onClick={() => setActiveTab(index)}
// //                         >
// //                             {tab.label}
// //                         </li>
// //                     ))}
// //                 </ul>
// //                 <div className="tab-content">
// //                     <h2 className='feature_head'>{tabs[activeTab].heading}</h2>
// //                     <p className='text-dark'>{tabs[activeTab].text}</p>
// //                     <div className="card-container">
// //                         {tabs[activeTab].cards.map((card, cardIndex) => (
// //                             <div key={cardIndex} className="card">
// //                                 <img width='50px' src={card.img} alt={card.title} className="card-image" />
// //                                 <h3 className='choose_heading text-center'>{card.title}</h3>
// //                                 <p className='text_cards text-center'>{card.description}</p>
// //                             </div>
// //                         ))}
// //                     </div>
// //                 </div>
// //             </div>
// //             <LetsStart />
// //             <Footer />
// //         </>
// //     );
// // };

// // export default Features;


import React from 'react'
import CustomNavbar from '../../Component/Navber'
import './App.css'
import LetsStart from '../../Component/LetsStart';
import Footer from '../../Component/Footer';
import Inventry from '../../../../asesst/inventryweb.png'
import salesreturn from '../../../../asesst/sales-return.png'
import customer from '../../../../asesst/customer (3).png'
import Acount from '../../../../asesst/acountimg.png'
import Store from '../../../../asesst/store.png'
import UserRoll from '../../../../asesst/userRoll.png'
import mobile from '../../../../asesst/customizeMobile.png'
import Reports from '../../../../asesst/reports.png'
import Backup from '../../../../asesst/backup.png'
import customerimages from '../../../../asesst/customerimages.png'
import { AccesIcon, ActivtyIcon, AnlyticsIcon, AtmIcon, CheckoutIcon, CloudIcon, ControlIcon, EncryptionIcon, FlexibleIcon, HistryIcon, LoginIcon, LoyaltyIcon, OpraionIcon, PaymetIcon, ProfileIcon, RecoveryIcon, ReportIcon, SpecificIcon, TrackingIcon, TransferIcon, SupportIcon } from '../../../../component/icon';
const data = [
    {
        heading: "Point of Sale (POS) System",
        text: "Our intuitive POS system is at the heart of Tijarah Soft, enabling you to process sales quickly and efficiently...",
        imgSrc: salesreturn,
        features: [
            {
                title: "Quick Checkout",
                text: "Speed up transactions with fast and reliable checkout processes.",
                imgSrc: <CheckoutIcon color='#604be8' size='35px' />
            },
            {
                title: "Multiple Payment Methods",
                text: "Accept cash, credit cards, mobile payments, and more.",
                imgSrc: <PaymetIcon color='#604be8' size='35px' />
            },
            {
                title: "Sales Reporting",
                text: "Gain insights into your sales data with detailed reports.",
                imgSrc: <ReportIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Inventory Management",
        text: "Keep track of your stock levels effortlessly with Tijarah Soft's robust inventory management features. Monitor inventory in real time, automate reordering, and reduce stockouts to ensure you always have the products your customers want.",
        imgSrc: Inventry,
        features: [
            {
                title: "Real-Time Inventory Tracking",
                text: " Know what's in stock at all times.",
                imgSrc: <TrackingIcon color='#604be8' size='35px' />
            },
            {
                title: "Automated Reordering",
                text: "Set thresholds to automatically reorder products..",
                imgSrc: <AtmIcon color='#604be8' size='35px' />
            },
            {
                title: "Inventory Reports",
                text: "Analyze stock levels, turnover rates, and more.",
                imgSrc: <ReportIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Customer Relationship Management (CRM)",
        text: "Build and maintain strong relationships with your customers using our integrated CRM tools. Manage customer profiles, track purchase history, and tailor your marketing efforts to improve customer loyalty and drive repeat business.",
        imgSrc: customer,
        features: [
            {
                title: "Customer Profiles",
                text: " Store detailed customer information and preferences.",
                imgSrc: <ProfileIcon color='#604be8' size='25px' />
            },
            {
                title: "Purchase History",
                text: "Track past transactions to offer personalized service.",
                imgSrc: <HistryIcon color='#604be8' size='25px' />
            },
            {
                title: "Loyalty Programs",
                text: "Reward your customers and encourage repeat purchases.",
                imgSrc: <LoyaltyIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Multi-Store Management        ",
        text: "Manage multiple stores from a single platform with ease. Tijarah Soft allows you to oversee operations, track sales, and manage inventory across all your locations, ensuring consistency and efficiency.",
        imgSrc: Store,
        features: [
            {
                title: "Centralized Control",
                text: " Manage all your stores from one dashboard.",
                imgSrc: <ControlIcon color='#604be8' size='25px' />
            },
            {
                title: "Inventory Transfers",
                text: "Move stock between stores effortlessly.",
                imgSrc: <TransferIcon color='#604be8' size='25px' />
            },
            {
                title: "Store-Specific Reports",
                text: " Analyze performance at each location.",
                imgSrc: <SpecificIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Accounting and Financial Management       ",
        text: "Stay on top of your finances with Tijarah Soft's built-in accounting features. Track expenses, manage invoices, and generate financial reports to keep your business financially healthy and compliant",
        imgSrc: Acount,
        features: [
            {
                title: "Expense Tracking",
                text: " Monitor and categorize business expenses.",
                imgSrc: <TrackingIcon color='#604be8' size='25px' />
            },
            {
                title: "Invoice Management",
                text: " Create, send, and track invoices with ease.",
                imgSrc: <TransferIcon color='#604be8' size='25px' />
            },
            {
                title: "Financial Reports",
                text: " Generate balance sheets, profit and loss statements, and more.",
                imgSrc: <SpecificIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "User Access Control      ",
        text: "Maintain security and control over your business operations with customizable user access levels. Assign roles and permissions to ensure that your team members have the appropriate access to the tools they need.",
        imgSrc: UserRoll,
        features: [
            {
                title: "Role-Based Access",
                text: " Define roles with specific permissions.",
                imgSrc: <AccesIcon color='#604be8' size='25px' />
            },
            {
                title: "Activity Logs",
                text: " Monitor user activity for accountability.",
                imgSrc: <ActivtyIcon color='#604be8' size='25px' />
            },
            {
                title: "Secure Login",
                text: " Protect your data with secure authentication methods.",
                imgSrc: <LoginIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Offline Mode     ",
        text: "Tijarah Soft ensures uninterrupted business operations, even when the internet is down. Our offline mode allows you to continue processing transactions and managing inventory, with automatic syncing when the connection is restored.",
        imgSrc: Store,
        features: [
            {
                title: "Seamless Offline Operation",
                text: "Continue working without internet access.",
                imgSrc: <OpraionIcon color='#604be8' size='25px' />
            },
            {
                title: "Automatic Syncing",
                text: " Sync data automatically when you're back online.",
                imgSrc: <TransferIcon color='#604be8' size='25px' />
            },
            {
                title: "Reliable Performance",
                text: "Ensure smooth operations no matter the connectivity.",
                imgSrc: <SpecificIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Customizable Dashboards    ",
        text: "Get a clear overview of your business with customizable dashboards. Track key metrics, monitor sales, and stay informed with real-time data presented in a way that makes sense to you.",
        imgSrc: mobile,
        features: [
            {
                title: "Real-Time Analytics",
                text: "Access up-to-date information at a glance..",
                imgSrc: <AnlyticsIcon color='#604be8' size='25px' />
            },
            {
                title: "Custom Widgets",
                text: " Tailor your dashboard to display the data that matters most.",
                imgSrc: <TransferIcon color='#604be8' size='25px' />
            },
            {
                title: "Visual Reports",
                text: "Make informed decisions with easy-to-understand charts and graphs.",
                imgSrc: <ReportIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Comprehensive Reporting   ",
        text: "Make data-driven decisions with Tijarah Soft's powerful reporting tools. Generate detailed reports on sales, inventory, customer behavior, and financial performance to gain insights that drive your business forward.",
        imgSrc: Reports,
        features: [
            {
                title: "Sales Reports",
                text: "Analyze trends, top-selling products, and revenue.",
                imgSrc: <ReportIcon color='#604be8' size='25px' />
            },
            {
                title: "Inventory Reports",
                text: " Track stock levels, reorder points, and inventory turnover.",
                imgSrc: <ReportIcon color='#604be8' size='25px' />
            },
            {
                title: "Financial Reports",
                text: " Monitor your business's financial health with ease.",
                imgSrc: <ReportIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Secure Cloud Backup ",
        text: "Protect your business data with Tijarah Soft's secure cloud backup solution. Ensure your information is safe, accessible, and recoverable in the event of hardware failure or other disruptions.",
        imgSrc: Backup,
        features: [
            {
                title: "Automated Backups",
                text: "Schedule regular backups to the cloud.",
                imgSrc: <CloudIcon color='#604be8' size='25px' />
            },
            {
                title: "Data Encryption",
                text: " Safeguard your data with industry-standard encryption.",
                imgSrc: <EncryptionIcon color='#604be8' size='25px' />
            },
            {
                title: "Easy Recovery",
                text: "Restore your data quickly and easily when needed.",
                imgSrc: <RecoveryIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Scalable Solutions",
        text: "Whether you're a small business or growing enterprise, Tijarah Soft scales with you. Customize features, add users, and expand your operations effortlessly as your business evolves.",
        imgSrc: Store,
        features: [
            {
                title: "Flexible Plans",
                text: "Choose the plan that fits your business needs.",
                imgSrc: <FlexibleIcon color='#604be8' size='25px' />
            },
            {
                title: "User Management",
                text: " Easily add or remove users as your team grows.",
                imgSrc: <ProfileIcon color='#604be8' size='25px' />
            },
            {
                title: "Modular Features",
                text: "Add or remove features based on your business requirements.",
                imgSrc: <SpecificIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "24/7 Customer Support",
        text: "We understand that your business doesn't stop, and neither do we. Tijarah Soft offers 24/7 customer support to assist you whenever you need help, ensuring that any issues are resolved quickly and efficiently",
        imgSrc: customerimages,
        features: [
            {
                title: "Dedicated Support Team",
                text: " Get help from knowledgeable professionals.",
                imgSrc: <SupportIcon color='#604be8' size='25px' />
            },
            {
                title: "Multiple Support Channels",
                text: "  Reach us via phone, email, or live chat.",
                imgSrc: <SupportIcon color='#604be8' size='25px' />
            },
            {
                title: "Comprehensive Help Center",
                text: " Access tutorials, guides, and FAQs anytime.",
                imgSrc: <SupportIcon color='#604be8' size='25px' />
            },
        ],
    },
    // Add more sections here
];

function Features() {
    return (
        <div>
            <CustomNavbar />
            <div className='about__sub'>
                <div className='w-100'>
                    <h1 className='about_heading text-white'>Features</h1>
                    <a href='/' className='home_link'>Home</a>
                    <span className='slash_tyle'>/</span>
                    <a className='about_link' href='/features'>Features</a>
                </div>
            </div>

            {data.map((item, index) => (
                <div className={`cards_feature mt-5 gap-4 ${index % 2 === 0 ? '' : 'reverse'}`} key={index}>
                    <div className=' box__features'>
                        {index % 2 === 0 ? (
                            <div className='bg_img_style'>
                                <img width='100%' src={item.imgSrc} alt={item.heading} className='Feature__img'  />

                            </div>
                        ) : (
                            <div className='content_cards'>
                                <h3 className='main_heading_cards'>{item.heading}</h3>
                                <p>{item.text}</p>
                                {item.features.map((feature, i) => (
                                    <div className='d-flex  gap-4 py-2' key={i}>
                                        {feature.imgSrc}
                                        <div>
                                            <h3 className='crad_head'>{feature.title}</h3>
                                            <p className='card_text'>{feature.text}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className=' box__features'>
                        {index % 2 === 0 ? (
                            <div className='content_cards'>
                                <h3 className='main_heading_cards'>{item.heading}</h3>
                                <p>{item.text}</p>
                                {item.features.map((feature, i) => (
                                    <div className='d-flex  gap-4 py-2' key={i}>
                                        {feature.imgSrc}
                                        <div>
                                            <h3 className='crad_head'>{feature.title}</h3>
                                            <p className='card_text'>{feature.text}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className='bg_img_style'>
                                <img width='100%' src={item.imgSrc} alt={item.heading} className='Feature__img' />

                            </div>
                        )}
                    </div>
                </div>
            ))}
            <LetsStart />
            <Footer />
        </div>
    )
}

export default Features;
