import React from 'react';
import CustomNavbar from '../../Component/Navber';
import LetsStart from '../../Component/LetsStart';
import Footer from '../../Component/Footer';
import './style.css'
function PrivacyPolicy() {
    return (
        <>
            <CustomNavbar />
            <div className='heading_container'>
                <h1 className='main_heading' >Privacy Policy</h1>
            </div>
            <div className='content_container'>
                <p className='date_update'>Last updated: 25-08-2024</p>
                <p className='content'>
                    At Tijarah Soft, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to ensure your data remains secure.
                </p>
                <ol className='order_list_container'>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Information We Collect</h2>
                        <p>We collect various types of information to provide and improve our services, including:</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Personal Information:</span> <span>When you sign up for Tijarah Soft, we may collect your name, email address, phone number, and other contact details.</span></li>
                            <li><span className='list_item_sub_heading'>Payment Information:</span> <span>We collect payment details such as credit card information to process transactions securely.</span></li>
                            <li><span className='list_item_sub_heading'>Usage Data:</span> <span>We may collect information on how you interact with our software, including IP addresses, browser type, and activity logs.</span></li>
                            <li><span className='list_item_sub_heading'>Device Information:</span> <span>We collect data on the devices you use to access Tijarah Soft, including hardware model, operating system, and unique device identifiers.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>How We Use Your Information</h2>
                        <p>The information we collect is used to:</p>
                        <ul>
                            <li>Provide and maintain our services.</li>
                            <li>Process transactions and send related information, including purchase confirmations and invoices.</li>
                            <li>Improve, personalize, and expand our services.</li>
                            <li>Communicate with you, including responding to inquiries, providing support, and sending promotional content.</li>
                            <li>Analyze usage trends to better understand how users interact with our software.</li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Sharing Your Information</h2>
                        <p>We do not share your personal information with third parties, except in the following circumstances:</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Service Providers:</span> <span>We may share your information with trusted third-party vendors who assist us in operating our services, provided they adhere to strict data protection standards.</span></li>
                            <li><span className='list_item_sub_heading'>Legal Compliance:</span> <span>We may disclose your information if required by law, legal process, or government request.</span></li>
                            <li><span className='list_item_sub_heading'>Business Transfers:</span> <span>In the event of a merger, acquisition, or asset sale, your information may be transferred as part of that transaction.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Data Security</h2>
                        <p>We implement robust security measures to protect your data, including encryption, firewalls, and secure access controls. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Data Retention</h2>
                        <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Your Rights</h2>
                        <p>You have the right to:</p>
                        <ul>
                            <li>Access the personal information we hold about you.</li>
                            <li>Request correction or deletion of your data.</li>
                            <li>Object to the processing of your data.</li>
                            <li>Request a copy of your data in a structured, machine-readable format.</li>
                        </ul>
                        <p>To exercise these rights, please contact us at [Your Contact Information].</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Changes to This Privacy Policy</h2>
                        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated policy on our website.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Contact Us</h2>
                        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                        <p><strong>Tijarah Soft</strong></p>
                        <p><a href='mailto:support@tijarahsoft.com'>support@tijarahsoft.com</a></p>
                    </li>
                </ol>
                <p className='content'>
                    By using Tijarah Soft, you consent to the terms of this Privacy Policy.
                </p>
                <p className='content'>
                    Thank you for trusting Tijarah Soft with your information.
                </p>
            </div>
            <LetsStart />
            <Footer />
        </>
    )
}

export default PrivacyPolicy;