import React from 'react'
import './style.css'
import Button from '../../../../component/Button'
function LetsStart() {
    return (
        <div className='main__lets__start'>
            <div className='sec_bg_lets'>
                <h3 className='lets_head'>Let’s Get Started</h3>
                <h3 className='lets_pera'>Ready to boost your business? Let Tijarah Soft streamline your operations—get started today!</h3>
                <Button label='Start Free Trial' />

            </div>
        </div>
    )
}

export default LetsStart