import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function HeroSection({ image, subHeading, heading, buttonText, buttonUrl }) {
  const navigate = useNavigate();

  const handle = () => {
    navigate("/login");
  };

  console.log(buttonUrl, "buttonUrl;");

  return (
    <div className="main__hero__Store hdt-slider" id="home">
      <div className="hdt-slider__slide hdt-slideshow_item slide-item_label hdt-oh is-selected is-in-view">
        <div
          className="hdt-slide_inner hdt-relative hdt-media-overlap-content"
          style={{ backgroundColor: !image ? "#000000" : "" }}
        >
          {image && (
            <picture className="hdt-media-wrapper">
              <img
                src={image}
                alt=""
                width="1838"
                height="803"
                loading="eager"
                fetchPriority="high"
                sizes="100vw"
                className="hdt-object-pos-mb"
              />
            </picture>
          )}
          <div>
            <div className="hdt-content hdt-place-self-center md:hdt-place-self-center-start">
              <div className="hdt-content-inner hdt-text-left md:hdt-text-left hdt-content-bg_false hdt-rounded">
                {/* Subheading animation - top to center */}
                <motion.p
                  className="hdt-slide_subheading hdt-base hdt-font-bold hdt-s-text2 hdt-slide_label hdt-label-bg_false hdt-inline-flex"
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.8, ease: "easeOut" }}
                >
                  {subHeading}
                </motion.p>

                {/* Heading animation - right to center */}
                <motion.h2
                  className="hdt-slide_heading hero-main-heading hdt-h2 hdt-font-semibold hdt-s-text"
                  initial={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8, delay: 0.3, ease: "easeOut" }}
                >
                  {heading}
                </motion.h2>
                <motion.div
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 2, ease: "easeOut" }}
                >
                  <div className="hdt-slide_button hdt-inline-flex hdt-group_btn hdt-align-center hdt-justify-center">
                    {/* Button text animation - left to center */}
                    {buttonText && (
                      <a
                        href={buttonUrl}
                        target="_blank"
                        className="hdt-btn hdt-text-xl hdt-oh hdt-inline-flex hdt-align-center hdt-justify-center hdt-relative hdt-btn-solid hdt-font-medium"
                        style={{ opacity: 1, transform: "translateY(0px)" }}
                      >
                        <span>{buttonText}</span>
                        <svg
                          className="hdt-icon hdt-icon-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 64 64"
                          fill="currentColor"
                        >
                          <path d="M12,56.43,36.48,32,12,7.52,19.53,0,51.61,32,19.53,64Z"></path>
                        </svg>
                      </a>
                    )}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
