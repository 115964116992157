import React from 'react';
import './style.css';
import Button from '../../../../component/Button';
import { useNavigate } from 'react-router-dom';

function HeroSection() {
    const navigate = useNavigate();

    const handleGetStartedClick = () => {
        navigate('/login'); // Replace '/login' with the correct path to your login screen
    };

    return (
        <div className='main__hero compelet__screen_perfect' id='home'>
            <div className='back__img'>
                <h1 className='hero__head'>
                    <span>Boost Efficiency </span>and Sales with Advanced <span>Tijarah Soft Solutions.</span>
                </h1>
                <p className='heo__pera'>
                    Efficient, reliable, and tailored for modern businesses, Tijarah Soft POS simplifies your operations. From seamless transactions to inventory management and real-time reporting, our software empowers you to run your business smoothly, whether you're managing a small shop or a large enterprise.
                </p>
                <div>
                    <Button label='Start Free Trial' onClick={handleGetStartedClick} />
                </div>
            </div>

            <div className='background__img'></div>
        </div>
    );
}

export default HeroSection;
