import React from "react";
import "./style.css";
import {
  FacebookIcon,
  LinkinIcon,
  InstagramIcon,
} from "../../../../component/icon";
import { useContext } from "react";
import { ContentContext } from "../../../../store";
import Logo from "../Navbar/logo";

function Footer({ credit, isSocialMedia, socialMedia, logo }) {
  const { storeName } = useContext(ContentContext);
  return (
    <div className="main_footer_Store">
      <div className="screen-spacing mt-0">
        <div className="row py-5 align-items-center ">
          <div className="col-12 col-md-4 footer_grid order-3 order-md-1  justify-content-center justify-content-md-start">
            {" "}
            <p className="last_text_footer mt-3 mt-md-0">{credit}</p>
          </div>
          <div className="col-12 col-md-4 footer_grid justify-content-center order-1 order-md-2">
            <Logo name={storeName} logo={logo} />
          </div>
          {isSocialMedia && (
            <div className="col-12 col-md-4  order-2 order-md-3 ">
              <div className="footer_flex  gap-3 footer_grid   justify-content-center  justify-content-md-end">
                {socialMedia?.map((social, index) => {
                 
                  return (
                    <a href={social?.url} target="_blank" key={index}>
                      {social?.name === "Facebook" ? (
                        <FacebookIcon />
                      ) : social?.name === "LinkedIn" ? (
                        <LinkinIcon />
                      ) : social?.name === "Instagram" ? (
                        <InstagramIcon />
                      ) : null}
                    </a>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Footer;
