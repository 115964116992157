import axios from "axios";
import { BASE_URL } from "..";

export const fetchShipping = async (domain, city) => {
    const response = await axios.get(`${BASE_URL}/shipping/city`, {
        headers: {
            domain,
            city
        }
    });
    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
}
