import React from 'react';
import CustomNavbar from '../../Component/Navber';
import LetsStart from '../../Component/LetsStart';
import Footer from '../../Component/Footer';
import './style.css'
function TermsAndConditions() {
    return (
        <>
            <CustomNavbar />
            <div className='heading_container'>
                <h1 className='main_heading' >Terms and Conditions</h1>
            </div>
            <div className='content_container'>
                <p className='date_update'>Last updated: 25-08-2024</p>
                <p className='content'>
                    Welcome to Tijarah Soft! By accessing or using our software and services, you agree to comply with and be bound by the following Terms and Conditions. Please read these terms carefully before using our services. If you do not agree to these terms, please do not use our services.
                </p>
                <ol className='order_list_container'>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Acceptance of Terms</h2>
                        <p>By using Tijarah Soft, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions and our Privacy Policy. We may update these terms from time to time, and your continued use of our services constitutes acceptance of any changes.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Services Provided</h2>
                        <p>Tijarah Soft provides business management software, including but not limited to point-of-sale (POS) systems, inventory management, customer relationship management (CRM), and financial management tools. Our services are designed to help businesses manage their operations efficiently.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>User Responsibilities</h2>
                        <ul>
                            <li><span className='list_item_sub_heading'>Account Information:</span> <span>You are responsible for maintaining the confidentiality of your account information, including your username and password. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</span></li>
                            <li><span className='list_item_sub_heading'>Use of Services:</span> <span>You agree to use our services only for lawful purposes and in accordance with these Terms and Conditions. You must not use our services in any way that could damage, disable, or impair the operation of our software or interfere with other users' enjoyment of our services.</span></li>
                            <li><span className='list_item_sub_heading'>Data Accuracy:</span> <span>You are responsible for ensuring that all data you provide or input into our software is accurate and up-to-date.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Intellectual Property</h2>
                        <p>All content, features, and functionality of Tijarah Soft, including software, text, graphics, logos, and trademarks, are the exclusive property of Tijarah Soft and are protected by intellectual property laws. You may not use, copy, modify, distribute, or create derivative works based on our intellectual property without our express written consent.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>License Grant</h2>
                        <p>Subject to your compliance with these Terms and Conditions, we grant you a limited, non-exclusive, non-transferable, and revocable license to use our software and services for your internal business purposes. This license does not include the right to sublicense, distribute, or resell our software or services.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Subscription and Payment</h2>
                        <ul>
                            <li><span className='list_item_sub_heading'>Subscription Plans:</span> <span>Our services may be offered on a subscription basis, with different plans and pricing tiers available. Details of subscription plans, pricing, and payment terms will be provided on our website or through our sales representatives.</span></li>
                            <li><span className='list_item_sub_heading'>Billing:</span> <span>You agree to pay all fees and charges associated with your subscription in accordance with our billing terms. We reserve the right to change our fees and billing practices at any time.</span></li>
                            <li><span className='list_item_sub_heading'>Refunds:</span> <span>Refunds are subject to our refund policy, which may be outlined separately or provided upon request.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Termination</h2>
                        <p>We may suspend or terminate your access to our services if you breach these Terms and Conditions or if we determine that your use of our services is harmful or inappropriate. You may terminate your subscription by following the procedures outlined in your account settings or by contacting our support team.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Limitation of Liability</h2>
                        <p>To the fullest extent permitted by law, Tijarah Soft and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our services. Our total liability for any claim arising from your use of our services shall be limited to the amount paid by you for the service that is the subject of the claim.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Indemnification</h2>
                        <p>You agree to indemnify, defend, and hold harmless Tijarah Soft, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, or expenses arising out of or related to your use of our services, your violation of these Terms and Conditions, or any infringement of any third-party rights.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Privacy</h2>
                        <p>Your use of our services is governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using our services, you consent to the collection and use of your information as described in the Privacy Policy.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Severability</h2>
                        <p>If any provision of these Terms and Conditions is found to be invalid or unenforceable, the remaining provisions shall continue in full force and effect. The invalid or unenforceable provision shall be modified to reflect the parties' original intent as closely as possible.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Entire Agreement</h2>
                        <p>These Terms and Conditions, along with our <a href='/privacy-policy'>Privacy Policy</a>, constitute the entire agreement between you and Tijarah Soft regarding your use of our services and supersede any prior agreements or understandings.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Contact Us</h2>
                        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                        <p><strong>Tijarah Soft</strong></p>
                        <p><a href='mailto:support@tijarahsoft.com'>support@tijarahsoft.com</a></p>
                    </li>
                </ol>
            </div>
            <LetsStart />
            <Footer />
        </>
    )
}

export default TermsAndConditions;