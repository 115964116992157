import { Box } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Features from "./Component/Features";
import "./index.css";
import AnnouncementBar from "./Component/AnnouncementBar";
import Navbar from "./Component/Navbar";
import HeroSection from "./Component/HeroSection";
import BestProduct from "./Component/BestProduct";
import WhatsappBtn from "./Component/WhatsappBtn";
import CategorySilder from "./Component/CategorySilder";
import Footer from "./Component/Footer";
import { ContentContext } from "../../store";
import { createVisitor } from "../../apis/views";
import { fetchDomain } from "../../helper";
import MetaTags from "react-meta-tags";
import OpenCart from "./Component/OpenCart";
import Loader from "../../asesst/loader.gif";
function Home({ domain }) {
  const {
    products,
    categories,
    domainInfo,
    domianFetchErr,
    domainFetchStatus,
  } = useContext(ContentContext);
  useEffect(() => {
    document.documentElement.style.fontSize = "62.5%";
    return () => {
      document.documentElement.style.fontSize = "16px";
    };
  }, []);

  const createStoreVisitor = async () => {
    try {
      await createVisitor({ domain: fetchDomain() });
    } catch (error) {}
  };

  useEffect(() => {
    createStoreVisitor();
  }, []);
  useEffect(() => {
    if (domainInfo?.tawkTo) {
      // Parse and insert the script into the document
      const scriptTag = document.createElement("div");
      scriptTag.innerHTML = domainInfo.tawkTo; // Assuming `tawkTo` contains the HTML with the script
      const scriptElement = scriptTag.querySelector("script");

      // Execute the script if available
      if (scriptElement) {
        const newScript = document.createElement("script");
        newScript.type = "text/javascript";
        newScript.async = true;
        newScript.innerHTML = scriptElement.innerHTML; // Set script content
        document.body.appendChild(newScript);

        // Clean up the script when component unmounts
        return () => {
          document.body.removeChild(newScript);
        };
      }
    }
  }, [domainInfo?.tawkTo]);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (domainInfo || domianFetchErr) {
      setLoader(false);
    }
  }, [domianFetchErr, domainInfo]);

  return (
    <>
      {loader ? (
        <>
          <div className="loader-image">
            <img src={Loader} height={"200px"} width={"200px"} alt="loader" />
          </div>
        </>
      ) : (
        <>
          {domainInfo?.storeName ? (
            <>
              <MetaTags>
                <title>{domainInfo?.storeName}</title>
              </MetaTags>
              <Box className="store">
                {domainInfo?.topNavbar && (
                  <AnnouncementBar
                    note={domainInfo?.topNavbarNote}
                    mobile={domainInfo?.topNavbarMobile}
                    email={domainInfo?.topNavbarEmail}
                  />
                )}
                <Navbar domain={domain} logo={domainInfo?.logo} />
                {domainInfo?.heroSection && (
                  <HeroSection
                    heading={domainInfo?.heroSectionHeading}
                    subHeading={domainInfo?.heroSectionSubHeading}
                    buttonText={domainInfo?.heroSectionButtonText}
                    buttonUrl={domainInfo?.heroSectionButtonLink}
                    image={domainInfo?.heroSectionImage}
                  />
                )}

                <CategorySilder categories={categories} />
                {domainInfo?.isBanners && (
                  <Features features={domainInfo?.banners} />
                )}
                <BestProduct
                  products={products}
                  domain={domain}
                  heading={domainInfo?.productsHeading}
                />
                <Footer
                  credit={domainInfo?.footerCredit}
                  logo={domainInfo?.footerLogo}
                  socialMedia={domainInfo?.socialMedia}
                  isSocialMedia={domainInfo?.isSocialMedia}
                />
                <OpenCart domain={domain} initialProducts={products} />
                {domainInfo?.isWhatsappMessageAllowed &&
                  domainInfo?.whatsapp && (
                    <WhatsappBtn number={domainInfo?.whatsapp} />
                  )}
              </Box>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <h1>
                  {" "}
                  <span style={{ fontSize: "45px", fontWeight: "800" }}>
                    {domainFetchStatus}
                  </span>{" "}
                  {domianFetchErr}
                </h1>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Home;
