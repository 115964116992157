import axios from "axios";
import { BASE_URL } from "..";

export const fetchDomainInfo = async (domain) => {
  const response = await axios.get(`${BASE_URL}/domains/info/${domain}`, {
    headers: {
      domain,
    },
  });
  // if (response.status === 200) {
  //     return response.data;
  // } else {
  //     return false;
  // }
  return response;
};
