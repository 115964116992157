import axios from "axios";
import { BASE_URL } from "..";


export const accountRegister = async (data) => {
    const ip = await axios.get('https://api.ipify.org?format=json');
    if (ip) {
        const response = await axios.post(`${BASE_URL}/user/register`, { ...data, ip: ip.data.ip });
        if (response.status === 201) {
            return response.data;
            alert(response)
        } else {
            return false;
        }
    } else {
        return false;
    }

}
