import React from 'react'
import './styles.css'
function WhatsappBtn({ number }) {
    return (
        <a href={`https://api.whatsapp.com/send?phone=+92${number}&text=Hi`} id="whatsapp-float" target="_blank">
            <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" alt="WhatsApp" />
        </a>
    )
}

export default WhatsappBtn