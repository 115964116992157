import React from 'react';
import CustomNavbar from '../../Component/Navber';
import LetsStart from '../../Component/LetsStart';
import Footer from '../../Component/Footer';
import './style.css'
function RefundPolicy() {
    return (
        <>
            <CustomNavbar />
            <div className='heading_container'>
                <h1 className='main_heading' >Refund Policy</h1>
            </div>
            <div className='content_container'>
                <p className='date_update'>Last updated: 25-08-2024</p>
                <ol className='order_list_container'>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Refund Eligibility</h2>
                        <p>Refunds are available for clients who are dissatisfied with the software or services provided by Tijarah Soft under the following conditions:</p>
                        <ul>
                            <li><span>The request for a refund is made within 30 days of the purchase date.</span></li>
                            <li><span>The client has made a reasonable effort to resolve any issues with our support team.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Non-Refundable Items</h2>
                        <p>The following items are non-refundable:</p>
                        <ul>
                            <li>Customized software features or configurations that were specifically requested by the client.</li>
                            <li>Services that have already been performed, such as installations or training sessions.</li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Refund Process</h2>
                        <p>To request a refund, clients must contact our support team via email at support@tijarahsoft.com with the following information:</p>
                        <ul>
                            <li><span>Purchase receipt or invoice number</span></li>
                            <li><span>Reason for the refund request</span></li>
                            <li><span>Details of any issues encountered</span></li>
                        </ul>
                        <p>Our support team will review the request and determine if the refund conditions are met. Once approved, refunds will be processed within 14 business days.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Partial Refunds</h2>
                        <p>In some cases, partial refunds may be issued for specific services or components of the software that were unsatisfactory, while other aspects of the service remain valid.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Changes to Refund Policy</h2>
                        <p>Tijarah Soft reserves the right to modify this refund policy at any time. Any changes will be communicated to clients and will apply to purchases made after the date of the policy update.</p>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Contact Us</h2>
                        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                        <p><strong>Tijarah Soft</strong></p>
                        <p><a href='mailto:support@tijarahsoft.com'>support@tijarahsoft.com</a></p>
                    </li>
                </ol>
                <p className='content'>
                    By using Tijarah Soft, you consent to the terms of this Privacy Policy.
                </p>
                <p className='content'>
                    Thank you for trusting Tijarah Soft with your information.
                </p>
            </div>
            <LetsStart />
            <Footer />
        </>
    )
}

export default RefundPolicy;