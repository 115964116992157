
import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
import AppRoute from './config/route';
import { ToastContainer } from 'react-toastify';
import { ContentProvider } from './store';


function App() {
  return (
    <>
      <ChakraProvider>
        <ContentProvider>
          <AppRoute />
          <ToastContainer />
        </ContentProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
