import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentContext } from '../../../../store';
import AnnouncementBar from '../../Component/AnnouncementBar';
import BestProduct from '../../Component/BestProduct';
import Footer from '../../Component/Footer';
import Banner from '../Cart/Component/Banner';
import './style.css';
import Navbar from '../../Component/Navbar';
import WhatsappBtn from '../../Component/WhatsappBtn';


function ThanksOrder({ domain }) {
  const navigate = useNavigate();
  const { products, storeName, domainInfo } = useContext(ContentContext);

  useEffect(() => {
    document.documentElement.style.fontSize = "62.5%";
    return () => {
      document.documentElement.style.fontSize = "16px";
    };
  }, []);

  return (
    <>
      {domainInfo?.topNavbar && <AnnouncementBar note={domainInfo?.topNavbarNote} mobile={domainInfo?.topNavbarMobile} email={domainInfo?.topNavbarEmail} />}
      <Navbar domain={domain} logo={domainInfo?.logo} />
      <Banner lable='Thank You' />
      <div className='thanks_main_conainer'>
        <div className='sub_box_thanks'>
          <h1 className='thanks_head'>Thank You!</h1>
          <p className='thans_text'>We appreciate your trust in {storeName}. Your order is being processed, and we'll notify you once it's on its way. If you have any questions, feel free to contact us. We hope you love your purchase and look forward to serving you again!</p>
          <button onClick={() => navigate('/')} className='go_home'>Go to home</button>
        </div>
      </div >
      <BestProduct initialVisible={3} products={products} domain={domain} />
      <Footer credit={domainInfo?.footerCredit} logo={domainInfo?.footerLogo} socialMedia={domainInfo?.socialMedia} isSocialMedia={domainInfo?.isSocialMedia} />
      {domainInfo?.isWhatsappMessageAllowed && domainInfo?.whatsapp && <WhatsappBtn number={domainInfo?.whatsapp} />}
    </>
  );
}

export default ThanksOrder;
