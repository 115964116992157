import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Placeholder from "../../../../../asesst/placeholder.jpeg";
import { createSlug } from "../../../../../helper";
import { ContentContext } from "../../../../../store";
import { motion } from "framer-motion";

const BestProductCard = ({
  product,
  src,
  link,
  domain,
  title,
  price,
  className,
}) => {
  const navigate = useNavigate();
  const [isInCart, setIsInCart] = useState(false);
  const { setIsModalOpen, setselectedItems } = useContext(ContentContext);

  // State to track hover
  const [isHovered, setIsHovered] = useState(false);

  // Handle adding or removing the product from the cart
  const handleAddCart = async () => {
    const storedProducts = localStorage.getItem(`${domain}-carts`);
    let products = storedProducts ? JSON.parse(storedProducts) : [];

    // Check if the product is already in the cart
    const productIndex = products.findIndex((item) => item.id === product?.id);

    if (productIndex > -1) {
      // Product is in the cart, remove it
      products.splice(productIndex, 1);
      setIsModalOpen(true);
      setselectedItems(products);
    } else {
      const price =
        product?.itemType === "Variants"
          ? Math.min(
              ...product?.combinations?.map(
                (combination) => Number(combination?.retailPrice) || 0
              )
            )
          : Number(product?.retailPrice);

      // Product is not in the cart, add it
      const selectedVariant = product?.combinations?.find(
        (combination) => Number(combination?.retailPrice) === price
      );

      products.push({
        ...product,
        selectedQty: 1,
        retailPrice: price,
        selectedVariant: selectedVariant?.variantName,
      });
      setIsModalOpen(true);
      setselectedItems(products);
    }

    // Update localStorage
    localStorage.setItem(`${domain}-carts`, JSON.stringify(products));
    checkProductInCart(); // Re-check if product is in the cart after adding/removing
  };

  const checkProductInCart = () => {
    const carts = JSON.parse(localStorage.getItem(`${domain}-carts`)) || [];
    const productInCart = carts.some((item) => item.id === product?.id);
    setIsInCart(productInCart);
  };

  useEffect(() => {
    checkProductInCart();
  }, [product]);

  const handleView = async () => {
    navigate(`/product/${createSlug(product?.itemname)}`);
    window.location.reload();
  };

  return (
    <div
      style={{ position: "relative" }}
      className={`hdt-card-product__wrapper hdt-oh hdt-pr-img__effect-zoom ${className}`}
      // Set hover state
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="hdt-card-product__media hdt-relative">
        <Link to={link} className="hdt-card-product__media-wrapp hdt-ratio">
          <img
            src={src ?? Placeholder}
            alt={title}
            width="795"
            height="795"
            loading="lazy"
            className="hdt-card-product__media--hover"
          />
        </Link>
        <div className="hdt-badge__wrapp hdt-pe-none">
          {/* <div className="hdt-badge hdt-badge__on-sale">Sale</div> */}
        </div>
      </div>
      <div className="hdt-card-product__info hdt-card-align-left pt-3">
        <Link
          to={link}
          className="hdt-card-product__title hdt-text-lg hdt-font-normal hdt-line-clamp"
          onClick={handleView}
        >
          {title}
        </Link>
        <div className="div-wrapp hdt-text-base hdt-font-semibold">
          <div className="div__list">
            <div className="div">
              <span className="hdt-money">{price} Rs</span>
            </div>
          </div>
        </div>
      </div>

      {/* Example usage of isHovered state */}
      {isHovered && (
        <motion.div
          initial={{ opacity: 0, y: 20 }} // Start with opacity 0 and slightly below the final position
          animate={{ opacity: 1, y: 0 }} // Animate to full opacity and original position
          exit={{ opacity: 0, y: 20 }} // Fade out and move back down when exiting
          transition={{ duration: 0.3 }} // Duration of the animation
        >
          <div
            className="hdt-product-btns"
            style={{
              position: "absolute",
              top: "3",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {product?.count > 0 && (
              <button
                type="button"
                onClick={handleAddCart}
                className="hdt-card-product__btn-ultra hdt-pr_btn"
              >
                {isInCart ? (
                  // Check Icon
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M9 17.5l-5-5 1.4-1.4 3.6 3.6L18.6 7l1.4 1.4-11 11z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  // Cart Icon
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M5 4h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1M2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3zm10 7c-2.761 0-5-2.686-5-6h2c0 2.566 1.67 4 3 4s3-1.434 3-4h2c0 3.314-2.239 6-5 6"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                <span className="sr-only">Quick add</span>
              </button>
            )}

            <button
              type="button"
              onClick={handleView}
              className="hdt-card-product__btn-quick-view hdt-pr_btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                >
                  <path d="M15 12a3 3 0 1 1-6 0a3 3 0 0 1 6 0" />
                  <path d="M2 12c1.6-4.097 5.336-7 10-7s8.4 2.903 10 7c-1.6 4.097-5.336 7-10 7s-8.4-2.903-10-7" />
                </g>
              </svg>
              <span className="sr-only">Quick view</span>
            </button>
          </div>
        </motion.div>
      )}
      <div style={{ height: "50px" }}></div>
    </div>
  );
};

export default BestProductCard;
